export const getLine = (title, desc, value, main=false, valuePerc=null) => {
  const textSize = main ? 'text-2xl' : 'text-lg'
  const titleBold = main ? 'font-bold' : ''
  return <>
    <div className="grid grid-cols-4 gap-2 mb-2">
      <div className="flex flex-col col-span-3">
        <dt className={`text-sm font-medium text-am-600 ${titleBold}`}>{title}</dt>
        <dt className="text-xs font-medium text-gray-500">{desc}</dt>
      </div>
      <div>
        <dd className={`m-1 ${textSize} font-semibold text-am-600 inline-block`}>{value}</dd>
        <dd className={"m-1 text-sm text-am-600 inline-block"}>{valuePerc}</dd>
      </div>
    </div>
  </>
}


export const getMarginiLine = (title, value, main=false) => {
  const textSize = main ? 'text-xl font-semibold' : 'text-m'
  const titleSize = main ? 'font-bold text-lg' : ''
  return <>
    <div className="grid grid-cols-5 gap-2">
      <div className="flex flex-col col-span-3">
        <dt className={`font-medium text-am-600 ${titleSize}`}>{title}</dt>
      </div>
      <div>
        <dd className={`m-1 ${textSize} text-am-600 inline-block whitespace-nowrap`}>{value}</dd>
      </div>
    </div>
  </>
}
