import React, {forwardRef} from "react";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ViewColumn from "@material-ui/icons/ViewColumn";

import {
  CheckCircleIcon,
  FilterIcon,
  MinusSmIcon,
  PencilIcon,
  PlusCircleIcon,
  SaveIcon,
  SearchIcon,
  TrashIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/outline";

const classSmall = "flex-shrink-0 h-4 w-4 text-gray-400 group-hover:text-gray-500"
const classMedium = "flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"

export const tableIcons = {
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),

  Add: forwardRef((props, ref) => <PlusCircleIcon {...props} ref={ref} className={classMedium}/>),
  Clear: forwardRef((props, ref) => <XCircleIcon {...props} ref={ref} className={classMedium}/>),
  Check: forwardRef((props, ref) => <CheckCircleIcon {...props} ref={ref} className={classMedium}/>),
  Delete: forwardRef((props, ref) => <TrashIcon {...props} ref={ref} className={classMedium}/>),
  Edit: forwardRef((props, ref) => <PencilIcon {...props} ref={ref} className={classMedium}/>),
  Export: forwardRef((props, ref) => <SaveIcon {...props} ref={ref} className={classSmall}/>),
  Filter: forwardRef((props, ref) => <FilterIcon {...props} ref={ref} className={classSmall}/>),
  ResetSearch: forwardRef((props, ref) => <XIcon {...props} ref={ref} className={classSmall}/>),
  Search: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} className={classSmall}/>),
  ThirdStateCheck: forwardRef((props, ref) => <MinusSmIcon {...props} ref={ref} className={classSmall}/>),
};
