import React, {useContext, useEffect, useState} from "react";

// Service
import AuthenticationService from "../../services/AuthenticationService";
import {DataContext} from "../../services/DataContext";
import apiRequest from "../../services/apiRequest";
import _ from "lodash";

// Components
import { PageHeading } from "../../ui-components/Container";
import PreventiviTable from "../../components/Tables/PreventiviTable";
import ContatoreVisite from "./contatoreVisite";
import SummaryKpi from "./summaryKpi";
import AddPrev from "./AddPrev";

// Ui
import Button from "../../ui-components/Button";
import { PlusCircleIcon, BackspaceIcon } from "@heroicons/react/solid";
import ErrorBoundary from "../../ui-components/ErrorBoundary";
import Placeholder from "../../ui-components/Placeholder";
import Select from "../../ui-components/Select";


export default function Preventivi() {

  const dataContext = useContext(DataContext);
  const [data, setData] = useState();
  const [conf, setConf] = useState();
  const [toUpdate, setToUpdate] = useState();
  const [view, setView] = useState('list');

  const [contatore, setContatore] = useState();

  const user = AuthenticationService.getUserData()
  const scopes = AuthenticationService.getUserScopes();
  const canCreatePrev = scopes.indexOf("prev:create") >= 0;
  const canSeeCosts = scopes.indexOf("prev:costs") >= 0;
  const canReadAll = scopes.indexOf("prev:read_all") >= 0;
  const isAdmin = scopes.indexOf("conf:read") >= 0;

  const otherIds = (user['other_customer_ids'] || "").split(";").filter(i => i !== '')
  const isTitolareWithMultipleViews = canSeeCosts && !isAdmin && otherIds.length > 0

  const [cid, setCid] = useState(user.customer_id);

  useEffect(() => {

    const fetchData = () => {
      apiRequest.get("preventivi")
        .then((res) => {
          setData((res || []).reduce((obj, i) => _.set(obj, [i.id], i), {}))
        })
        .catch(() => {console.error("Error when fetching data")})
    };

    const fetchConf = () => {
      apiRequest.get("conf")
        .then((res) => {setConf(res)})
        .catch(() => {console.error("Error when fetching data")})
    };

    const fetchCustomers = () => {
      const url = isTitolareWithMultipleViews ? "customers/multiple_customers" : "customers"
      apiRequest.get(url)
        .then((res) => {
          dataContext.setCustomers((res || []).map(i => ({value: i['id'], name: i['name']})))
        })
        .catch(() => {console.error("Error when fetching data")})
    };

    if (!data) {
      fetchData();
    }
    if (!conf) {
      fetchConf()
    }
    if ((isAdmin || isTitolareWithMultipleViews) && !dataContext.customers) {
      fetchCustomers()
    }
  }, [data, conf, isAdmin, isTitolareWithMultipleViews, dataContext.customers]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <>
      {view === 'list' &&
        <div className="grid gap-4 grid-cols-1 md:grid-cols-8 md:grid-flow-col md:gap-10">
          {(isAdmin || isTitolareWithMultipleViews) && dataContext.customers ?
            <div className="md:col-span-2 mt-4">
              <Select
                options={dataContext.customers}
                value={cid}
                onChange={(e) => setCid(e.target.value)}
                label="Cliente"
              />
            </div>
            : <div className="md:col-span-1" />
          }
          <SummaryKpi cid={cid} contatore={contatore} />
          <ContatoreVisite cid={cid} contatore={contatore} setContatore={setContatore} />
        </div>
      }
      <PageHeading
        title={view === 'list' ? 'Preventivi' : toUpdate ? 'Modifica preventivo' : 'Aggiungi preventivo'}
        contentActions={
          canCreatePrev ? (
            <Button
              styleType={view === "list" ? "primary" : "secondary"}
              onClick={() => {
                const to = view === 'list' ? 'add' : 'list'
                setToUpdate(undefined)
                setView(to)
              }}
            >
              {view === 'list'
                ? <><PlusCircleIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true"/>{" "}Aggiungi</>
                : <><BackspaceIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true"/>{" "}Indietro</>
              }
            </Button>
          ) : null
        }
      />
      {!conf &&
        <div>
          <Placeholder height="h-40" classNames="rounded mb-3"/>
          <Placeholder height="h-40" classNames="rounded mb-3"/>
          <Placeholder height="h-40" classNames="rounded mb-3"/>
        </div>
      }
      {view === 'list' && conf &&
      <ErrorBoundary from="preventivi-table">
        <PreventiviTable
          data={data}
          filterCid={((isAdmin || isTitolareWithMultipleViews) && Number(cid) !== 1) && cid}
          setToUpdate={setToUpdate}
          setView={setView}
          conf={conf}
          canSeeCosts={canSeeCosts}
          canReadAll={canReadAll}
        />
      </ErrorBoundary>
      }
      {canCreatePrev && view === 'add' && conf &&
      <ErrorBoundary from="prev-add-dialog">
        <AddPrev
          data={data}
          setData={setData}
          setView={setView}
          toUpdate={toUpdate}
          conf={conf}
          canSeeCosts={canSeeCosts}
        />
      </ErrorBoundary>
      }
    </>
  );
}
