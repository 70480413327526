import AuthenticationService from "../services/AuthenticationService";

const filterScopesByRoutes = (routes) => {
  const scopes = AuthenticationService.getUserScopes();
  return routes.filter((item) => {
    if (!item.scopes && !item.dropdown) {
      return true;
    }

    if (item.dropdown) {
      const dropdownScopes = item.dropdown.reduce(
        (acc, drop) => [...acc, ...drop.scopes],
        []
      );
      if (!dropdownScopes.length) {
        return true;
      }

      return dropdownScopes.some((r) => scopes.indexOf(r) >= 0);
    }

    if (item.scopes.some((r) => scopes.indexOf(r) >= 0)) {
      return true;
    }

    return false;
  });
};

export default filterScopesByRoutes;
