// Api URL based on env
export const API_URL = "/api";

export const MESI = {
  0: "Gennaio",
  1: "Febbraio",
  2: "Marzo",
  3: "Aprile",
  4: "Maggio",
  5: "Giugno",
  6: "Luglio",
  7: "Agosto",
  8: "Settembre",
  9: "Ottobre",
  10: "Novembre",
  11: "Dicembre"
}

export const TABS = [
  {name: 'Home'},
  {name: 'Prodotti e modelli'},
  {name: 'Analisi mercato'}
]