import React, { useState, useEffect, useContext } from "react";

// Services
import { NotificationsContext} from "../../ui-components/Notifications";
import ErrorBoundary from "../../ui-components/ErrorBoundary";
import apiRequest from "../../services/apiRequest";

// UI
import Placeholder from "../../ui-components/Placeholder";
import BarChartProdotti from "../../components/Charts/BarChartProdotti";

function Prodotti({cid, vid, valuePerc, startPeriod, endPeriod, flgPeriodContract}) {
  const [data, setData] = useState();
  const { push } = useContext(NotificationsContext);

  useEffect(() => {
    const fetchData = () => {
      console.log('fetchProdotti')
      apiRequest
        .get(`/analytics/prodotti/${cid}/${vid}/${startPeriod.format("YYYY-MM-DD")}/${endPeriod.format("YYYY-MM-DD")}/${flgPeriodContract}`)
        .then((res) => {
          setData(res);
        })
        .catch(() => {
          push({ title: "Errore durante il caricamento", type: "error" });
        });
    };

    fetchData();

  }, [cid, vid, startPeriod, endPeriod, push, flgPeriodContract]);

  return (
    <>
      <ErrorBoundary from="visite">
        {!data &&
          <>
            <div className="m-10"><Placeholder height="h-32" classNames="rounded mb-3"/></div>
            <div className="m-10"><Placeholder height="h-32" classNames="rounded mb-3"/></div>
          </>
        }
        {data &&
          <>
            {(data || []).length > 0
             ? <BarChartProdotti id="bar-chart-prodotti" data={data} valuePerc={valuePerc} categoryField="des_prodotto"/>
              : <div className="text-center m-16">
                <b>Non sono presenti preventivi nel periodo selezionato</b>
              </div>
            }
        </>
        }
      </ErrorBoundary>
    </>
  );
}

export default Prodotti;
