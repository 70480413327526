import AuthenticationService from "../../../services/AuthenticationService";

const Logout = () => {
  AuthenticationService.logout();

  window.location = "/login";

  return null;
};

export default Logout;
