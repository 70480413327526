import React from "react";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';

const DatePicker = ({ value, label, onChange }) => {
  return (
    <div className="flex flex-col mb-1">
      {label ? <label className="block text-sm text-gray-500">{label}</label> : null}
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="DD/MM/YYYY"
            value={value}
            onChange={onChange}
            style={{minWidth: 140, maxWidth: 150, marginTop: 5}}
          />
        </MuiPickersUtilsProvider>
    </div>
  );
};
export default DatePicker;
