import React from "react";


const GPDR = () => {

  return (
    <p className="mt-2 text-center text-xs text-gray-600 mb-6 font-medium">
      La presente Informativa sulla privacy descrive le modalità con cui i dati personali vengono raccolti e utilizzati quando visiti https://preventivi.organizzazionecintiseverini.it/ (il “Sito”) da parte di Organizzazione Cinti Severini (Via Di Vittorio 82, 60131, Ancona).
      <br/><br/>
      RACCOLTA DATI PERSONALI<br/>
      Raccogliamo e archiviamo le informazioni necessarie per l'utilizzo delle funzionalità della piattaforma. I dati personali sono necessari solamente per l'autenticazione e l'autorizzazione all'accesso ai dati, pertanto il trattamento si limita all'utilizzo dell'indirizzo email, che è richiesto per il login al Sito.
      <br/><br/>
      UTILIZZO DEI DATI PERSONALI<br/>
      I contatti degli utenti registrati alla piattaforma non verranno utilizzate per altre finalità, come analisi statistica o scopi di marketing.
      <br/><br/>
      CONSERVAZIONE E SICUREZZA DEI DATI<br/>
      I dati inseriti all'interno del Sito sono conservate all'interno degli archivi della piattaforma in modo da permette l'analisi storica agli utenti, fino a quando l'utente non richiede di eliminare le proprie informazioni o elimina il proprio account. La piattaforma archivia i dati in modo sicuro, su server protetti all'interno dell'Unione Europea.
      <br/><br/>
      USO DEI COOKIES<br/>
      La WebApp OCS non utilizza Cookies al fine di modificare l'esperienza utente all'interno della piattaforma.
      <br/><br/>
      ACCETTAZIONE DELLA POLICY<br/>
      Usando il Sito, assicuri di aver letto e accettato queste condizioni. Per qualunque informazione puoi scrivere a organizzazionecintiseverini@gmail.com
    </p>
  );
};

export default GPDR;
