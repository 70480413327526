import React, {useEffect, useState} from "react";

// UI
import { PageHeading } from "../../ui-components/Container";
import apiRequest from "../../services/apiRequest";

import ProdTable from "../../components/Tables/ProdTable";
import LineTable from "../../components/Tables/LineTable";
import ModelTable from "../../components/Tables/ModelTable";
import {Grid} from "@material-ui/core";
import _ from "lodash";

function Config() {
  const [prod, setProd] = useState();
  const [line, setLine] = useState();
  const [model, setModel] = useState();

  const fetchData = () => {
    apiRequest.get("conf")
      .then((res) => {
        setProd(res['prodotti'].reduce((obj, i) => _.set(obj, [i.id], i), {}))
        setLine(res['linee'].reduce((obj, i) => _.set(obj, [i.id], i), {}))
        setModel(res['modelli'].reduce((obj, i) => _.set(obj, [i.id], i), {}))
      })
      .catch(() => {console.error("Error when fetching data")})
  };

  useEffect(() => {
    if (!prod) {
      fetchData();
    }
  }, [prod]);

  return (
    <div>
      <PageHeading title="Configurazione" />
      <Grid container spacing={5}>
        <Grid item>
          <ProdTable prod={prod} setProd={setProd}/>
        </Grid>
        <Grid item>
          <LineTable line={line} setLine={setLine}/>
        </Grid>
        <Grid item>
          <ModelTable model={model} setModel={setModel} prod={prod} line={line}/>
        </Grid>
      </Grid>

    </div>
  );
}

export default Config;
