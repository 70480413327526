import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {getXYChart, getRenderAxisX, getRenderAxisY} from "./commons";


function BarChartSummary({id, data}) {

  useLayoutEffect(() => {

    am5.addLicense("AM5C328990290");
    let root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)])

    // Create chart
    let chart = getXYChart(root, {layout: root.verticalLayout})

    // Create axes
    let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "category",
      renderer: getRenderAxisY(root, false, true, 0),
      paddingLeft: 20
    }))

    let yRenderer = yAxis.get("renderer");
    yRenderer.labels.template.setAll({
      fontSize: "0.7em"
    });


    yAxis.data.setAll(data);

    let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: getRenderAxisX(root, false),
      min: 0,
      strictMinMax: true,
      extraMax: 0.1
    }))

    let xRenderer = xAxis.get("renderer");
    xRenderer.labels.template.setAll({
      forceHidden: true
    });

    // Add series
    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: "measure",
      categoryYField: "category"
    }))

    series.columns.template.adapters.add("fill", function(fill, target) {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });

    series.columns.template.setAll({
      fillOpacity: 0.7,
      cornerRadiusTR: 5,
      cornerRadiusBR: 5,
    });

    series.bullets.push(function () {
    return am5.Bullet.new(root, {
      locationX: 1,
      locationY: 0.5,
      sprite: am5.Label.new(root, {
        text: "[bold]{label}[/]",
        centerY: am5.p50,
        fontSize: 10,
        populateText: true
      })
    });
  });

    series.data.setAll(data);
    series.appear(1000);


    return () => {
      root.dispose()
    }
  }, [data, id]) // eslint-disable-line react-hooks/exhaustive-deps

  return <div id={id} style={{minWidth: "330px", minHeight: "120px" }}/>
}

export default React.memo(BarChartSummary, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
