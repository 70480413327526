import React, { useCallback, useState } from "react";
import Input from "../../ui-components/Input";
import Modal from "../../ui-components/Modal";
import Button from "../../ui-components/Button";
import apiRequest from "../../services/apiRequest";

function AddCustomerDialog({ open, toggleOpen, customers, setCustomers, push }) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        if (!name) {
          push({
            title: "Inserisci il nome",
            type: "error",
          });
          return;
        }

        setLoading(true);
        const data = await apiRequest.post("/customers", {name});
        setCustomers({ ...customers, [data['id']]: data });
        toggleOpen();
        push({
          title: "Elemento creato con successo",
          type: "success",
        });
      } catch (error) {
        push({
          title: "Nome già in uso!",
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [name, setCustomers, toggleOpen, customers, push]
  );

  if (!open) {
    return null;
  }

  return (
    <div>
      <Modal onExit={toggleOpen}>
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            Aggiungi titolare
          </h3>
        </div>

        <form id="add-user-form" onSubmit={_onSubmit}>
          <div>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              label="Ragione commerciale"
              type="text"
              errorMessage="Inserisci ragione commerciale"
              required
            />
          </div>
          <div className="mt-4 flex items-end justify-end">
            <Button submitting={loading} type="submit">
              AGGIUNGI
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddCustomerDialog;
