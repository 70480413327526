import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";

// Services
import AuthenticationService from "../../../services/AuthenticationService";
import { NotificationsContext } from "../../Notifications";
import apiRequest from "../../../services/apiRequest";
import ErrorBoundary from "../../ErrorBoundary";

// Components
import RoleTable from "./RoleTable";
import AddRoleDialog from "./AddRoleDialog";

// UI
import { PlusCircleIcon } from "@heroicons/react/solid";
import { PageHeading } from "../../Container";
import Button from "../../Button";
import Badge from "../../Badge";
import UpdateScopes from "./UpdateScopes";

const COLUMNS = [
  {
    title: "Id",
    field: "id",
    editable: false
  },
  {
    title: "Nome",
    field: "name",
    render: ({ name }) => <b>{name}</b>,
  },
  {
    cellStyle: { width: 600, minWidth: 600 },
    headerStyle: { width: 600, minWidth: 600 },
    title: "Scopes",
    field: "scopes",
    editComponent: (props) => <UpdateScopes {...props} />,
    render: ({ scopes }) =>
      !scopes ? (
        "-"
      ) : (
        <div>
          {scopes.split(";").map((s) => (
            <Badge classNames="mb-1 mr-1" text={s} key={s} />
          ))}
        </div>
      ),
  },
];

const Roles = () => {
  const [roles, setRoles] = useState();
  const [showNewRoleDialog, setShowNewRoleDialog] = useState(false);
  const scopes = AuthenticationService.getUserScopes();
  const { push } = useContext(NotificationsContext);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const roles = await apiRequest.get("/roles");
        setRoles(
          (roles || []).reduce(
            (rolesObj, role) => _.set(rolesObj, [role.id], role),
            {}
          )
        );
      } catch (error) {
        push({ title: "Errore durante il caricamento", type: "error" });
      }
    };

    if (!roles) {
      fetchRoles();
    }
    // eslint-disable-next-line
  }, [roles]);

  const toggleNewRoleDialog = () => setShowNewRoleDialog(!showNewRoleDialog);
  const canCreate = scopes.indexOf("role:create") >= 0;

  return (
    <>
      <PageHeading
        title="Gestione ruoli"
        contentActions={
          canCreate ? (
            <Button
              variant="contained"
              color="primary"
              onClick={toggleNewRoleDialog}
            >
              <PlusCircleIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              Aggiungi
            </Button>
          ) : null
        }
      />
      <ErrorBoundary from="roles-table">
        <RoleTable
          rows={roles}
          setRow={setRoles}
          push={push}
          apiKey="roles"
          roleKey="role"
          columns={COLUMNS}
        />
      </ErrorBoundary>
      {canCreate ? (
        <ErrorBoundary from="roles-add-dialog">
          <AddRoleDialog
            open={showNewRoleDialog}
            toggleOpen={toggleNewRoleDialog}
            roles={roles}
            setRoles={setRoles}
          />
        </ErrorBoundary>
      ) : null}
    </>
  );
};

export default Roles;
