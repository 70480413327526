import React from "react";
import types from "./constants/status-types-config";

const Alert = ({
  text,
  title,
  type = "info",
  onConfirm,
  confirmText = "OK",
  onDismiss,
  dismissText = "ANNULLA",
  slim,
}) => {
  if (!types[type]) {
    throw new Error("missing alert type");
  }

  const {
    Icon,
    classes: {
      light,
      textRegular,
      textExtraBold,
      textBold,
      hoverLight,
      focusOffset,
      focus,
    },
  } = types[type];

  return (
    <div className={`rounded-md ${light} px-4 ${slim ? "py-2" : "py-4"}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon className={`h-5 w-5 ${textRegular}`} aria-hidden="true" />
        </div>
        <div className="ml-3">
          {title ? (
            <h3 className={`text-sm font-medium ${textExtraBold}`}>{title}</h3>
          ) : null}
          {text ? (
            <div className={`mt-2 text-sm ${textBold}`}>
              <p>{text}</p>
            </div>
          ) : null}
          {onConfirm || onDismiss ? (
            <div className="mt-4">
              <div className="-mx-2 -my-1.5 flex">
                {onConfirm ? (
                  <button
                    onClick={onConfirm}
                    type="button"
                    className={`${light} px-2 py-1.5 rounded-md text-sm font-medium ${textExtraBold} ${hoverLight} focus:outline-none focus:ring-2 focus:ring-offset-2 ${focusOffset} ${focus}`}
                  >
                    {confirmText}
                  </button>
                ) : null}
                {onDismiss ? (
                  <button
                    onClick={onDismiss}
                    type="button"
                    className={`ml-3 ${light} px-2 py-1.5 rounded-md text-sm font-medium ${textExtraBold} ${hoverLight} focus:outline-none focus:ring-2 focus:ring-offset-2 ${focusOffset} ${focus}`}
                  >
                    {dismissText}
                  </button>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Alert;
