import React from "react";
import Spinner from "../../Spinner";
import SearchSelect from "../../SearchSelect";

const SelectOtherCustomers = ({ value, onChange, customers }) => {

  if (!customers) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }

  const _value = value ? customers
    .filter(i => value.split(';').includes(i.id.toString()))
    .map((c) => ({value: c.id, label: c.name}))
    : []

  return (
    <div className="w-56">
      <SearchSelect
        options={customers.map((r) => ({ value: r.id, label: r.name }))}
        value={_value}
        onChange={(v) => {onChange(v ? v.map(i => i.value).join(';') : '' )}}
        isMulti
      />
    </div>
  );
};

export default SelectOtherCustomers;
