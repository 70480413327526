import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";

// Services
import AuthenticationService from "../../../services/AuthenticationService";
import { NotificationsContext } from "../../Notifications";
import apiRequest from "../../../services/apiRequest";
import ErrorBoundary from "../../ErrorBoundary";

// Components
import AddUserDialog from "./AddUserDialog";
import UsersTable from "./UsersTable";

// UI
import { UserAddIcon } from "@heroicons/react/solid";
import { PageHeading } from "../../Container";
import Button from "../../Button";

function Users() {
  const [users, setUsers] = useState();
  const [showNewUserDialog, setShowNewUserDialog] = useState(false);
  const scopes = AuthenticationService.getUserScopes();
  const { push } = useContext(NotificationsContext);

  useEffect(() => {
    const fetchUsers = () => {
      apiRequest
        .get("/users")
        .then((users) => {
          setUsers(
            (users || []).reduce(
              (usersObj, user) => _.set(usersObj, [user.id], user),
              {}
            )
          );
        })
        .catch(() => {
          push({ title: "Errore durante il caricamento", type: "error" });
        });
    };

    if (!users) {
      fetchUsers();
    }
  }, [users, push]);

  const toggleNewUserDialog = () => setShowNewUserDialog(!showNewUserDialog);
  const canCreateUser = scopes.indexOf("user:create") >= 0;

  return (
    <>
      <PageHeading
        title="Gestione utenze"
        contentActions={
          canCreateUser ? (
            <Button
              variant="contained"
              color="primary"
              onClick={toggleNewUserDialog}
            >
              <UserAddIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              Aggiungi
            </Button>
          ) : null
        }
      />

      <ErrorBoundary from="users-table">
        <UsersTable users={users} setUsers={setUsers} push={push} />
      </ErrorBoundary>
      {canCreateUser ? (
        <ErrorBoundary from="users-add-dialog">
          <AddUserDialog
            open={showNewUserDialog}
            toggleOpen={toggleNewUserDialog}
            users={users}
            setUsers={setUsers}
          />
        </ErrorBoundary>
      ) : null}
    </>
  );
}

export default Users;
