import React from "react";
import SelectRole from "./SelectRole";
import SelectCustomer from "./SelectCustomer";
import SelectOtherCustomers from "./SelectOtherCustomers";
import _ from "lodash";


export const getColumns = (roles, customers) => {

  return [
    {
      title: "E-mail",
      field: "email",
    },
    {
      title: "Nome",
      field: "full_name",
      render: ({ full_name }) => <b className="capitalize">{full_name}</b>,
      cellStyle: {whiteSpace: "nowrap"}
    },
    {
      title: "Ruolo",
      field: "role_id",
      lookup: roles.reduce((obj, i) => _.set(obj, [i.id], i.name), {}),
      render: ({ role }) => <b className="capitalize">{role.name}</b>,
      editComponent: (props) => <SelectRole {...props} roles={roles} />,
    },
    {
      title: "Punto vendita",
      field: "customer_id",
      lookup: customers.reduce((obj, i) => _.set(obj, [i.id], i.name), {}),
      render: ({ customer }) => <p className="capitalize">{customer.name}</p>,
      editComponent: (props) => <SelectCustomer {...props} customers={customers} />,
      headerStyle: {whiteSpace: "nowrap"}
    },
    {
      title: "Altri punti vendita",
      field: "other_customer_ids",
      filtering: false,
      render: ({ other_customer_ids }) => <p>{other_customer_ids ? other_customer_ids.split(';').length : null}</p>,
      editComponent: (props) => <SelectOtherCustomers {...props} customers={customers} />,
      headerStyle: {whiteSpace: "nowrap"}
    },
    {
      title: "Disabilitato",
      field: "disabled",
      type: "boolean",
    },
  ];
}

export const OPTIONS = {
  filtering: true,
  pageSize: 10,
  searchFieldAlignment: "left",
  searchFieldStyle: {marginTop: "2px"},
  headerStyle: {fontWeight: "bold", fontSize: 14},
  showTitle: false,
  toolbar: false,
};

export const LOCALIZATION = {
  header: {
    actions: "",
  },
  body: {
    editRow: {
      deleteText: "Eliminare l'utenza?",
    },
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} utenti",
    labelRowsSelect: "Utenti",
  },
};
