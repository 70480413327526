import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {getPieChart} from "./commons";


function DonutPreventivi({id, data}) {

  useLayoutEffect(() => {

    am5.addLicense("AM5C328990290");
    let root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)])

    // Create chart
    let chart = getPieChart(root)


    // Add series
    let series = chart.series.push(am5percent.PieSeries.new(root, {
      valueField: "value",
      categoryField: "category",
      legendValueText: "[bold]{value}[/] ({valuePercentTotal.formatNumber('0.')}%)"
    }))

    // Disabling labels and ticks
    series.labels.template.set("visible", false);
    series.ticks.template.set("visible", false);

    series.slices.template.setAll({
      fillOpacity: 0.7,
      visible: true,
      stroke: am5.color("#fff"),
      tooltipText: "Preventivi {category}: [bold]{value}[/] ({valuePercentTotal.formatNumber('0.')}%)"
    });


    series.children.unshift(am5.Label.new(root, {
      text: data.reduce((acc, i) => acc + i.value, 0),
      fill: am5.color("rgb(0, 60, 80)"),
      fontSize: 20,
      fontWeight: "600",
      textAlign: "center",
      x: am5.percent(-3),
      y: am5.percent(-5),
      paddingTop: 0,
      paddingBottom: 0
    }));

    series.get("colors").set("colors", [
      am5.color("rgb(224,103,83)"),
      am5.color("rgb(0, 60, 80)"),
      am5.color(0x5aaa95)
    ]);

    series.data.setAll(data)

    let legend = chart.children.push(am5.Legend.new(root, {
      centerY: am5.percent(50),
      y: am5.percent(50),
      marginTop: 15,
      marginBottom: 15,
      marginRight: 15,
      layout: root.verticalLayout
    }));

    legend.labels.template.setAll({
      fontSize: 12,
      fontWeight: "300"
    });

    legend.valueLabels.template.setAll({
      fontSize: 13,
      fontWeight: "400"
    });

    legend.data.setAll(series.dataItems);

    // Make stuff animate on load
    series.appear(1000)

    return () => {
      root.dispose()
    }
  }, [data, id]) // eslint-disable-line react-hooks/exhaustive-deps

  return <div id={id} style={{ minWidth: "400px", minHeight: "220px"}}/>
}

export default React.memo(DonutPreventivi, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
