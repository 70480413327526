import React from "react";

import Profile from "../pages/Profile";
import { Switch } from "react-router-dom";
import PrivateRoute from "../PrivateRoute";
import Container from "../Container";
import menu from "../AppNavigation/menu";
import AppNavigation from "../AppNavigation";
import filterRoutesByScopes from "../../utils/filterRoutesByScopes";

function Dashboard() {
  return (
    <AppNavigation>
      <Container>
        <Switch>
          {filterRoutesByScopes(menu)
            .reduce(
              (acc, route) => [
                ...acc,
                ...(route.dropdown ? route.dropdown : [route]),
              ],
              []
            )
            .map((route) => {
              return (
                <PrivateRoute
                  key={route.href}
                  exact
                  component={route.component}
                  path={route.href}
                  role={route.role}
                />
              );
            })}
          <PrivateRoute exact path="/profile" component={Profile} />
        </Switch>
      </Container>
    </AppNavigation>
  );
}

export default Dashboard;
