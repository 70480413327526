import React from "react";

export const COLUMNS = [
  {
    title: "Id",
    field: "id",
    editable: false
  },
  {
    title: "Ragione commerciale",
    field: "name",
    render: ({ name }) => <b className="capitalize">{name}</b>,
  }
]

export const OPTIONS = {
  filtering: true,
  search: false,
  showTitle: true,
  toolbar: false,
  pageSize: 10,
  sorting: true,
  headerStyle: {fontWeight: "bold", fontSize: 14, padding: 12},
  cellStyle: {padding: 12}
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  body: {
    editRow: {
      deleteText: "Eliminare l'elemento?",
    },
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} righe",
    labelRowsSelect: "Righe",
  }
};
