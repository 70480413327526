import React from "react";
import types from "./constants/status-types-config";

const Badge = ({ text, type = "info", classNames = "" }) => {
  if (!types[type]) {
    throw new Error("missing badge type");
  }

  const {
    classes: { textRegular, textExtraBold, regular },
  } = types[type];

  return (
    <span
      className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${regular} ${textExtraBold} ${classNames}`}
    >
      <svg
        style={{ minWidth: "0.5rem", minHeight: "0.5rem" }}
        className={`-ml-1 mr-1.5 h-2 w-2 ${textRegular}`}
        fill="currentColor"
        viewBox="0 0 8 8"
      >
        <circle cx="4" cy="4" r="3" />
      </svg>
      {text}
    </span>
  );
};

export default Badge;
