import React, {createContext, useState} from 'react';
const DataContext = createContext({});
const {Provider} = DataContext;


const DataProvider = ({children}) => {

  const [customers, setCustomers] = useState();
  const [venditori, setVenditori] = useState();

  return (
    <Provider
      value={{
        customers, setCustomers: (v) => setCustomers(v),
        venditori, setVenditori: (v) => setVenditori(v),
      }}
    >
      {children}
    </Provider>
  );
};

export {DataContext, DataProvider}
