import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";


function BasicGauge({id, data}) {

  useLayoutEffect(() => {

    am5.addLicense("AM5C328990290");
    let root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)])

    // Create chart
    let chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        startAngle: 180,
        endAngle: 360
      })
    );

    chart.getNumberFormatter().set("numberFormat", "#'%'");

    let axisRenderer = am5radar.AxisRendererCircular.new(root, {
      innerRadius: -15
    });

    axisRenderer.grid.template.setAll({
      stroke: root.interfaceColors.get("background"),
      visible: true,
      strokeOpacity: 0.8,
    });

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        max: 100,
        strictMinMax: true,
        renderer: axisRenderer
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      fontSize: 10,
    });

    // Add clock hand
    // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Clock_hands
    let axisDataItem = xAxis.makeDataItem({});

    let clockHand = am5radar.ClockHand.new(root, {
      pinRadius: 25,
      radius: am5.percent(100),
      innerRadius: 30,
      bottomWidth: 1,
      topWidth: 0
    });

    clockHand.pin.setAll({
      fillOpacity: 0,
      strokeOpacity: 0.5,
      stroke: am5.color(0x000000),
      strokeWidth: 1,
      strokeDasharray: [2, 2]
    });
    clockHand.hand.setAll({
      fillOpacity: 0,
      strokeOpacity: 0.5,
      stroke: am5.color(0x000000),
      strokeWidth: 0.5
    });

    let bullet = axisDataItem.set(
      "bullet",
      am5xy.AxisBullet.new(root, {
        sprite: clockHand
      })
    );

    xAxis.createAxisRange(axisDataItem);

    let label = chart.radarContainer.children.push(
      am5.Label.new(root, {
        centerX: am5.percent(50),
        textAlign: "center",
        centerY: am5.percent(50),
        fontSize: "1.2em"
      })
    );

    axisDataItem.set("value", 0);
    bullet.get("sprite").on("rotation", function () {
      let value = axisDataItem.get("value");
      label.set("text", Math.round(value).toString() + "%");
    });

    setInterval(function () {
      let value = data;

      axisDataItem.animate({
        key: "value",
        to: value,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic)
      });

      axisRange0.animate({
        key: "endValue",
        to: value,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic)
      });

      axisRange1.animate({
        key: "value",
        to: value,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic)
      });
    }, 500);

    chart.bulletsContainer.set("mask", undefined);

    let axisRange0 = xAxis.createAxisRange(
      xAxis.makeDataItem({
        above: true,
        value: 0,
        endValue: 50
      })
    );

    axisRange0.get("axisFill").setAll({
      visible: true,
      fill: am5.color(0x5aaa95),
      fillOpacity: 0.6,
      stroke: am5.color("#fff")
    });

    axisRange0.get("label").setAll({
      forceHidden: true
    });

    let axisRange1 = xAxis.createAxisRange(
      xAxis.makeDataItem({
        above: true,
        value: 50,
        endValue: 100
      })
    );

    axisRange1.get("axisFill").setAll({
      visible: true,
      fill: am5.color("rgb(0, 60, 80)"),
      fillOpacity: 0.7,
    });

    axisRange1.get("label").setAll({
      forceHidden: true
    });

    return () => {
      root.dispose()
    }
  }, [data, id]) // eslint-disable-line react-hooks/exhaustive-deps

  return <div id={id} style={{ minWidth: "270px", minHeight: "160px", marginBottom: 15 }}/>
}

export default React.memo(BasicGauge, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
