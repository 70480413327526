import React from "react";

// Services
import ErrorBoundary from "../../ui-components/ErrorBoundary";
import moment from "moment";

// UI
import {MESI} from "../../utils/constants";
import Placeholder from "../../ui-components/Placeholder";
import TrendVisite from "../../components/Charts/TrendVisite";
import {getLine} from "./utils";


function Visite({data}) {

  const currentMonth = data && MESI[moment(data[0]['mese']).month()]
  const lastMonth = data && data[1] && MESI[moment(data[1]['mese']).month()]
  const currentYear = data && moment(data[0]['mese']).year()
  const lastMonthYear = data && data[1] && moment(data[1]['mese']).year()


  return (
    <>
      <ErrorBoundary from="visite">
        <div className="sm:inline-block align-top">
        {data
          ? <div className="mt-3 px-2 py-2 bg-white shadow rounded-lg overflow-hidden sm:p-3 sm:w-64 sm:mr-24">
            {getLine(
              "Visite mese corrente",
              `${currentMonth} ${currentYear}`,
              data[0]['num_visite'],
              true
            )}
            {lastMonth && getLine(
              "Visite mese scorso",
              `${lastMonth} ${lastMonthYear}`,
              data[1]['num_visite']
            )}
            {getLine(
              "Media visite mensile",
              'ultimi 12 mesi',
              ((data || []).reduce((acc, i) => acc + i['num_visite'], 0) / (data || []).length).toFixed(0) )}
          </div>
          : <div className="m-10">
            <Placeholder height="h-32" width="w-64" classNames="rounded mb-3"/>
          </div>
        }
        </div>
        <div className="inline-block">
        {data
          ? <TrendVisite id="trend-visite" data={data}/>
          : <div className="m-10"><Placeholder height="h-32" width="w-64" classNames="rounded mb-3"/></div>
        }
        </div>
      </ErrorBoundary>
    </>
  );
}

export default Visite;
