import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {getTooltip, getXYChart, addCursor, getRenderAxisX, getRenderAxisY, addSeriesBullet} from "./commons";


function TrendVisite({id, data}) {

  useLayoutEffect(() => {

    am5.addLicense("AM5C328990290");
    let root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)])
    root.dateFormatter.set("dateFormat", "MMM yyyy")
    root.locale = am5locales_it_IT;

    // Create chart
    let chart = getXYChart(root, {layout: root.verticalLayout})
    addCursor(root, chart)

    // Create axes
    let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      baseInterval: {timeUnit: "month", count: 1},
      renderer: getRenderAxisX(root, false)
    }))
    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxPrecision: 0,
      renderer: getRenderAxisY(root, false)
    }))


    // Add series
    let series = chart.series.push(am5xy.LineSeries.new(root, {
      minBulletDistance: 10,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "num_visite",
      valueXField: "mese",
      tooltip: getTooltip(root, "{mese.formatDate()}: [bold]{num_visite}[/]")
    }))

    // Add bullet
    addSeriesBullet(root, series)

    // Add data
    series.data.processor = am5.DataProcessor.new(root, {
      numericFields: ['num_visite'],
      dateFields: ['mese'],
      dateFormat: "yyyy-MM-dd"
    })
    series.data.setAll([...data].reverse())

    // Make stuff animate on load
    series.appear(1000)

    return () => {
      root.dispose()
    }
  }, [data, id]) // eslint-disable-line react-hooks/exhaustive-deps

  return <div id={id} style={{minWidth: "600px", minHeight: "200px"}}/>
}

export default React.memo(TrendVisite, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
