import React, { Fragment } from "react";
import AuthenticationService from "@services/AuthenticationService";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";

import { getInitials } from "@utils";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const UserBox = () => {
  const userData = AuthenticationService.getUserData();

  const { full_name, role_name } = userData;

  const initials = getInitials(full_name);

  return (
    <Menu
      as="div"
      className="mb-3 py-3 px-4 relative inline-block text-left w-full"
    >
      <div>
        <Menu.Button className="group w-full bg-gray-100 rounded-md px-2 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-am-500">
          <span className="flex w-full justify-between items-center">
            <span className="flex min-w-0 items-center justify-between space-x-2">
              <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-am-600">
                <span className="font-medium leading-none text-white">
                  {initials}
                </span>
              </span>
              <span className="flex-1 flex flex-col min-w-0">
                <span className="text-gray-900 text-sm font-medium truncate">
                  {full_name}
                </span>
                <span className="text-gray-500 text-xs truncate capitalize">
                  {role_name}
                </span>
              </span>
            </span>
            <SelectorIcon
              className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          id="menu"
          className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
        >
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/profile"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Profilo
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/logout"
                  onClick={(e) => {
                    e.preventDefault();
                    AuthenticationService.logout();
                    window.location = "/login";
                  }}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Logout
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default UserBox;
