import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {addCursor, getRenderAxisX, getRenderAxisY, getTooltip, getXYChart} from "./commons";


function BarChartProdotti({id, data, valuePerc, categoryField}) {

  useLayoutEffect(() => {

    am5.addLicense("AM5C328990290")
    let root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)])

    // Create chart
    let chart = getXYChart(root, {layout: root.verticalLayout })
    addCursor(root, chart)

    let legend = chart.rightAxesContainer.children.push(
      am5.Legend.new(root, {
        centerY: am5.p50,
        y: am5.p50,
      })
    );

    // Create axes
    let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: categoryField,
      renderer: getRenderAxisY(root, false, true, 0)
    }))

    yAxis.data.setAll(data);

    let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: getRenderAxisX(root, false),
      min: 0
    }))

    let xRenderer = xAxis.get("renderer");
    xRenderer.labels.template.setAll({
      forceHidden: true
    });

    // Add series
    function createSeries(field, name, color, final=false) {
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: field,
        stacked: true,
        categoryYField: categoryField,
        tooltip: getTooltip(root, "[bold]{name}[/] \n {categoryY}: {valueX}"),
        fill: color,
        fillOpacity: 0.5,
      }))

      series.columns.template.setAll({
        fillOpacity: 0.7,
        cornerRadiusBR: 5,
        cornerRadiusTR: 5,
        cornerRadiusBL: 5,
        cornerRadiusTL: 5
      });

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            text: valuePerc === 'value' ? "{valueX}" : "{valueX}%",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true
          })
        })
      });

      series.columns.template.onPrivate("width", function(height, target) {
        am5.array.each(target.dataItem.bullets, function(bullet) {
          if (height > 50 || bullet.get("locationX") === 1) {
            bullet.get("sprite").show();
          }
          else {
            bullet.get("sprite").hide();
          }
        });
      });

      if (final) {
        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              text: valuePerc === 'value' ? "[bold]{prev}[/]" : "",
              fill: root.interfaceColors.get("text"),
              centerY: am5.p50,
              populateText: true
            }),
            locationY: 0.5,
            locationX: 1
          })
        });
      }

      series.data.setAll(data);
      series.appear(1000);

      legend.data.push(series);
    }


    createSeries(valuePerc === 'value' ? 'prev_persi' : 'prev_persi_perc', 'Preventivi persi', am5.color("rgb(224,103,83)"))
    createSeries(valuePerc === 'value' ? 'prev_aperti' : 'prev_aperti_perc', 'Preventivi aperti', am5.color("rgb(0, 60, 80)"))
    createSeries(valuePerc === 'value' ? 'prev_chiusi' : 'prev_chiusi_perc', 'Contratti', am5.color(0x5aaa95), true)

    return () => {
      root.dispose()
    }
  }, [data, id, valuePerc]) // eslint-disable-line react-hooks/exhaustive-deps

  return <div id={id} style={{minWidth: "800px", marginBottom: "40px", minHeight:
      categoryField === 'des_linea' ? "200px" : categoryField === 'des_modello' && data.length > 12  ? "600px" : "300px"}}/>
}

export default React.memo(BarChartProdotti, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data) && _.isEqual(props.valuePerc, nextProps.valuePerc)
})
