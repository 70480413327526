import { createTheme } from "@material-ui/core/styles";

// Color import
import { blueGrey } from "@material-ui/core/colors";

const theme = createTheme({
  typography: {
    fontFamily: `"Open Sans", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif`,
  },
  palette: {
    background: {
      default: "#fff",
    },
    primary: {
      light: blueGrey[500],
      main: blueGrey[600],
      dark: blueGrey[700],
      contrastText: "#fff",
    },
    secondary: {
      light: blueGrey[500],
      main: blueGrey[600],
      dark: blueGrey[700],
      contrastText: "#fff",
    },
  },
});

export default theme;
