import React, { useState, useEffect, useContext } from "react";

// Services
import { NotificationsContext} from "../../ui-components/Notifications";
import ErrorBoundary from "../../ui-components/ErrorBoundary";
import apiRequest from "../../services/apiRequest";

// UI
import Placeholder from "../../ui-components/Placeholder";
import {PageSection} from "../../ui-components/Container";
import {getMarginiLine} from "./utils";

function Margini({cid, vid, startPeriod, endPeriod, flgPeriodContract}) {
  const [data, setData] = useState();
  const { push } = useContext(NotificationsContext);

  useEffect(() => {
    const fetchData = () => {
      console.log('fetchMargini')
      apiRequest
        .get(`/analytics/margini/${cid}/${vid}/${startPeriod.format("YYYY-MM-DD")}/${endPeriod.format("YYYY-MM-DD")}/${flgPeriodContract}`)
        .then((res) => {
          setData(res);
        })
        .catch(() => {
          push({ title: "Errore durante il caricamento", type: "error" });
        });
    };

    fetchData();

  }, [cid, vid, startPeriod, endPeriod, flgPeriodContract]); // eslint-disable-line react-hooks/exhaustive-deps

  const getOutput = (x, type) => {
    if (type === 'price') {
      return `€ ${x.toLocaleString('it-IT')}`
    } else if (type === 'perc') {
      return `${x} %`
    }
    return x
  }


  const section = (kpi, type, fasce = true) => {
    // const total = kpi.filter(x => x['aggregation'] === 'total')[0]
    return <div className="grid sm:grid-cols-2 sm:gap-10">
      {['all_prev', 'closed_prev'].map(val =>
      <div className="mb-2 px-2 py-2 bg-white shadow rounded-lg overflow-hidden">
        <p className="text-xl text-gray-500 text-center mb-4">{val === 'all_prev' ? 'Tutti i preventivi' : 'Contratti'}</p>
        {/*{getMarginiLine("Totale", getOutput(total[val], type), true)}*/}
        <p className="text-gray-500 my-2">Per prodotto</p>
        {kpi.filter(x => x['aggregation'] === 'prodotto').map(y => getMarginiLine(y['dimension'], getOutput(y[val], type)))}
        {fasce &&
          <>
            <p className="text-gray-500 my-2">Per fasce</p>
            {kpi.filter(x => x['aggregation'] === 'fascia').map(y => getMarginiLine(y['dimension'], getOutput(y[val], type)))}
          </>
        }
      </div>
      )}
    </div>
  }


  return (
    <>
      <ErrorBoundary from="valore">
        {!data &&
          <>
            <div className="m-10"><Placeholder height="h-32" classNames="rounded mb-3"/></div>
            <div className="m-10"><Placeholder height="h-32" classNames="rounded mb-3"/></div>
          </>
        }
        {data &&
          <>
            {(data || []).length > 0 && (data || []).filter(x => x['aggregation'] === 'total' && x['kpi'] === 'prezzo_medio')[0]['all_prev'] > 0
              ?
              <>
                <PageSection
                  title ="Prezzo medio"
                  children={section((data || []).filter(x => x['kpi'] === 'prezzo_medio'), 'price', false)}
                />
                <PageSection
                  title ="Sconto medio"
                  children={section((data || []).filter(x => x['kpi'] === 'sconto_medio'), 'perc')}
                />
                <PageSection
                  title ="Margine medio"
                  children={section((data || []).filter(x => x['kpi'] === 'margine_medio'), 'price')}
                />
                <PageSection
                  title ="Ricarico medio"
                  children={section((data || []).filter(x => x['kpi'] === 'ricarico_medio'), 'perc')}
                />
              </>
              : <div className="text-center m-16">
                <b>Non sono presenti preventivi nel periodo selezionato</b>
              </div>
            }
        </>
        }
      </ErrorBoundary>
    </>
  );
}

export default Margini;
