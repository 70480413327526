import React from "react";

// UI
import MaterialTable from "material-table";
import _ from "lodash";

import { tableIcons } from "../../TableIcons";
import AuthenticationService from "../../../services/AuthenticationService";
import apiRequest from "../../../services/apiRequest";

const OPTIONS = {
  filtering: true,
  pageSize: 10,
  searchFieldAlignment: "left",
  searchFieldStyle: {marginTop: "2px"},
  headerStyle: {fontWeight: "bold", fontSize: 14},
  showTitle: false,
  toolbar: false,
};

const LOCALIZATION = {
  header: {
    actions: "",
  },
  body: {
    editRow: {
      deleteText: "Eliminare l'elemento??",
    },
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count}",
    labelRowsSelect: "",
  },
};

const RoleTable = ({ rows, setRow, push, columns, apiKey, roleKey }) => {
  const scopes = AuthenticationService.getUserScopes();

  const _rows = rows && _.values(rows).map((user) => ({ ...user }));

  function manageUpdatedUser(oldData, newData) {
    const { [oldData.id]: tmp, ...rest } = rows;
    setRow({ ...rest, [newData.id]: newData });
  }

  const _onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      let isEqual = true;
      Object.entries(newData).forEach(([key, value]) => {
        if (oldData[key] !== value) {
          isEqual = false;
        }
      });
      setImmediate(() => {
        if (isEqual) {
          resolve();
        } else {
          apiRequest
            .post(`/${apiKey}/${oldData.id}`, newData)
            .then(() => {
              manageUpdatedUser(oldData, newData);
              push({title: "Dati aggiornati", type: "success"})
              resolve();
            })
            .catch(() => {
              push({title: "Errore imprevisto", type: "error"})
              reject();
            });
        }
      });
    });

  const _onRowDelete = (newData) =>
    new Promise((resolve, reject) => {
      setImmediate(() => {
        apiRequest
          .delete(`/${apiKey}/${encodeURIComponent(newData.id)}`)
          .then(() => {
            const { [newData.id]: tmp, ...rest } = rows;
            setRow({ ...rest });
            push({title: "Ruolo eliminato", type: "success"})
            resolve();
          })
          .catch(() => {
            push({title: "Errore imprevisto", type: "error"})
            reject();
          });
      });
    });

  return (
    <MaterialTable
      columns={columns}
      data={_rows}
      icons={tableIcons}
      isLoading={_rows === undefined}
      options={OPTIONS}
      localization={LOCALIZATION}
      editable={{
        ...(scopes.indexOf(`${roleKey}:update`) >= 0
          ? { onRowUpdate: _onRowUpdate }
          : {}),
        ...(scopes.indexOf(`${roleKey}:delete`) >= 0
          ? { onRowDelete: _onRowDelete }
          : {}),
      }}
    />
  );
};

export default React.memo(RoleTable, (props, nextProps) => {
  // Render component only when rows changes
  return _.isEqual(props.rows, nextProps.rows);
});
