import React, {useState, useEffect, useContext} from "react";

// Services
import {NotificationsContext} from "../../ui-components/Notifications";
import ErrorBoundary from "../../ui-components/ErrorBoundary";
import apiRequest from "../../services/apiRequest";
import moment from "moment";

// UI
import {MESI} from "../../utils/constants";
import Placeholder from "../../ui-components/Placeholder";
import {getLine} from "./utils";
import AreaPreventivi from "../../components/Charts/AreaPreventivi";
import DonutPreventivi from "../../components/Charts/DonutPreventivi";
import RadioGroup from "../../ui-components/RadioGroup";


function Preventivi({cid, vid, visitData}) {
  const [data, setData] = useState();
  const {push} = useContext(NotificationsContext);
  const [period, setPeriod] = useState('ytd');
  const [donutData, setDonutData] = useState([]);
  const [periodDesc, setPeriodDesc] = useState();
  const [kpi, setKpi] = useState({
    preventiviCreati: undefined,
    preventiviChiusi: undefined,
    virtuo: undefined,
    virtuoPerc: undefined,
    giorniChiusura: undefined,
    conversioneVisite: undefined,
    venduto: undefined
  })

  useEffect(() => {
    const fetchData = () => {
      apiRequest
        .get(`/analytics/preventivi/${cid}/${vid}`)
        .then((res) => {
          setData(res);
        })
        .catch(() => {
          push({title: "Errore durante il caricamento", type: "error"});
        });
    };

    fetchData();

  }, [cid, vid, push]);

  useEffect(() => {
    if (data && visitData) {
      if (period === 'all') {
        setDonutData([
          {category: 'Persi', value: (data || []).reduce((acc, i) => acc + i['prev_persi'], 0)},
          {category: 'Aperti', value: (data || []).reduce((acc, i) => acc + i['prev_aperti'], 0)},
          {category: 'Chiusi', value: (data || []).reduce((acc, i) => acc + i['prev_chiusi'], 0)}
        ])
        const prev = (data || []).reduce((acc, i) => acc + i['prev'], 0)
        const chiusi = (data || []).reduce((acc, i) => acc + i['chiusi_nel_mese'], 0)
        const venduto = (data || []).reduce((acc, i) => acc + i['venduto'], 0)
        const virtuo = (data || []).reduce((acc, i) => acc + i['chiusi_nel_mese_virtuo'], 0)
        const giorni = (data || []).reduce((acc, i) => acc + i['tot_giorni_chiusura'] || 0, 0)
        setKpi({
          preventiviCreati: prev,
          preventiviChiusi: chiusi,
          venduto: `€ ${venduto.toLocaleString('it-IT')} `,
          virtuo: virtuo,
          virtuoPerc: `$(${(virtuo / (chiusi + 0.0001) * 100).toFixed(0)}%)`,
          giorniChiusura: `${(giorni / (chiusi + 0.0001)).toFixed(0)}`,
          conversioneVisite: `${(prev / (visitData.reduce((acc, i) => acc + i['num_visite'], 0) + 0.0001) * 100).toFixed(0)}%`
        })
        setPeriodDesc('Ultimi 12 mesi')
      } else if (period === 'ytd') {
        const currentYear = moment(data[0]['mese']).year()
        const jan = moment(`${currentYear}-01-01`, 'YYYY-MM-DD')
        const ytdData = (data || []).filter(x => moment(x['mese']).isSameOrAfter(jan))
        const ytdVisit = (visitData || []).filter(x => moment(x['mese']).isSameOrAfter(jan))
        setDonutData([
          {category: 'Persi', value: ytdData.reduce((acc, i) => acc + i['prev_persi'], 0)},
          {category: 'Aperti', value: ytdData.reduce((acc, i) => acc + i['prev_aperti'], 0)},
          {category: 'Chiusi', value: ytdData.reduce((acc, i) => acc + i['prev_chiusi'], 0)}
        ])
        const prev = ytdData.reduce((acc, i) => acc + i['prev'], 0)
        const chiusi = ytdData.reduce((acc, i) => acc + i['chiusi_nel_mese'], 0)
        const venduto = ytdData.reduce((acc, i) => acc + i['venduto'], 0)
        const virtuo = ytdData.reduce((acc, i) => acc + i['chiusi_nel_mese_virtuo'], 0)
        const giorni = ytdData.reduce((acc, i) => acc + i['tot_giorni_chiusura'] || 0, 0)
        setKpi({
          preventiviCreati: prev,
          preventiviChiusi: chiusi,
          venduto: `€ ${venduto.toLocaleString('it-IT')}`,
          virtuo: virtuo,
          virtuoPerc: `(${(virtuo / (chiusi + 0.0001) * 100).toFixed(0)}%)`,
          giorniChiusura: `${(giorni / (chiusi + 0.0001)).toFixed(0)}`,
          conversioneVisite: `${(prev / (ytdVisit.reduce((acc, i) => acc + i['num_visite'], 0) + 0.0001) * 100).toFixed(0)}%`
        })
        setPeriodDesc(`Anno ${currentYear}`)
      } else {
        const index = period === 'current' ? 0 : 1
        setDonutData([
          {category: 'Persi', value: data[index]['prev_persi']},
          {category: 'Aperti', value: data[index]['prev_aperti']},
          {category: 'Chiusi', value: data[index]['prev_chiusi']}
        ])
        const virtuo = data[index]['chiusi_nel_mese_virtuo']
        const giorni = data[index]['tot_giorni_chiusura'] || 0
        setKpi({
          preventiviCreati: data[index]['prev'],
          preventiviChiusi: data[index]['chiusi_nel_mese'],
          venduto: `€ ${data[index]['venduto'].toLocaleString('it-IT')} `,
          virtuo: virtuo,
          virtuoPerc: `(${(virtuo / (data[index]['chiusi_nel_mese'] + 0.0001) * 100).toFixed(0)}%)`,
          giorniChiusura: `${(giorni / (data[index]['chiusi_nel_mese'] + 0.0001)).toFixed(0)}`,
          conversioneVisite: `${(data[index]['prev'] / (visitData[index]['num_visite'] + 0.0001) * 100).toFixed(0)}%`
        })
        const descMonth = MESI[moment(data[index]['mese']).month()]
        const descYear = moment(data[index]['mese']).year()
        setPeriodDesc(`${descMonth} ${descYear}`)
      }
    }
  }, [data, period, visitData])

  return (
    <>
      <ErrorBoundary from="visite">
        <div className="w-full flex flex-col md:flex-row">
          <div className="flex flex-col w-full md:w-1/2 justify-center">
            <RadioGroup
              id="period"
              horizontal
              options={[
                {value: "current", name: "Mese corrente"},
                {value: "previous", name: "Mese precedente"},
                {value: "ytd", name: "Anno corrente"},
                {value: "all", name: "Ultimi 12 mesi"},
              ]}
              currentValue={period}
              onChange={(value) => {
                setPeriod(value)
              }}
            />
            {data
              ? kpi.preventiviCreati ? <DonutPreventivi id="donut-preventivi" data={donutData}/> : null
              : <div className="m-10"><Placeholder height="h-32" width="w-64" classNames="rounded mb-3"/></div>
            }
          </div>
          <div className="flex flex-col w-full md:w-1/2 align-top">
            {data
              ? kpi.preventiviCreati
                ? <div className="mb-8 px-2 py-2 bg-white shadow rounded-lg overflow-hidden sm:p-3 sm:w-120  sm:ml-24">
                  {getLine("Preventivi creati", periodDesc, kpi.preventiviCreati, true)}
                  {getLine("Conversione visite", periodDesc, kpi.conversioneVisite)}
                  {getLine("Contratti chiusi", periodDesc, kpi.preventiviChiusi)}
                  {getLine("Di cui con Virtuo", periodDesc, kpi.virtuo, false, kpi.virtuoPerc)}
                  {getLine("Media giorni chiusura", periodDesc, kpi.preventiviChiusi ? kpi.giorniChiusura : '-')}
                  {getLine("Venduto", periodDesc, kpi.venduto)}
                </div> : null
              : <div className="m-10">
                <Placeholder height="h-32" width="w-64" classNames="rounded mb-3"/>
              </div>
            }
          </div>
        </div>
        {data
          ? kpi.preventiviCreati
            ? <AreaPreventivi id="trend-preventivi" data={data}/>
            : <div className="text-center m-16">
              <b>Non sono presenti preventivi nel periodo selezionato</b>
            </div>
          : <div className="m-10"><Placeholder height="h-32" classNames="rounded mb-3"/></div>
        }
      </ErrorBoundary>
    </>
  );
}

export default Preventivi;
