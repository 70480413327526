import {
  TrendingUpIcon,
  ClipboardListIcon,
  CogIcon
} from "@heroicons/react/outline";
import Analytics from "../pages/Analytics";
import Preventivi from "../pages/Preventivi";
import Config from "../pages/Config";

const routes = [
  {
    name: "Preventivi",
    href: "/preventivi",
    Icon: ClipboardListIcon,
    component: Preventivi,
    scopes: ["prev:read"]
  },
  {
    name: "Analytics",
    href: "/analytics",
    Icon: TrendingUpIcon,
    component: Analytics
  },
  {
    name: "Configurazione",
    href: "/config",
    Icon: CogIcon,
    component: Config,
    scopes: ["conf:read"]
  }
];

export default routes;
