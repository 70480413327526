import { UsersIcon } from "@heroicons/react/outline";
import routes from "../../routes";
import Roles from "../pages/Roles";
import Users from "../pages/Users";
import Customers from "../../pages/Customers";

const menu = [
  ...routes,
  {
    name: "Gestione Utenze",
    Icon: UsersIcon,
    dropdown: [
      {
        scopes: ["user:read"],
        href: "/users",
        name: "Utenti",
        component: Users,
      },
      {
        scopes: ["user:read"],
        href: "/customers",
        name: "Clienti",
        component: Customers,
      },
      {
        scopes: ["role:read"],
        href: "/roles",
        name: "Ruoli",
        component: Roles,
      },
    ],
  },
];

export default menu;
