import React, { useState, useEffect, useContext } from "react";

// Services
import { NotificationsContext} from "../../ui-components/Notifications";
import ErrorBoundary from "../../ui-components/ErrorBoundary";
import apiRequest from "../../services/apiRequest";

// UI
import Placeholder from "../../ui-components/Placeholder";
import BarChartProdotti from "../../components/Charts/BarChartProdotti";
import CheckboxGroup from "../../ui-components/CheckboxGroup";

function Linee({cid, vid, products, valuePerc, startPeriod, endPeriod, flgPeriodContract}) {
  const [data, setData] = useState();
  const [selectedProds, setSelectedProds] = useState([]);
  const { push } = useContext(NotificationsContext);

  useEffect(() => {
    const fetchData = () => {
      apiRequest
        .get(`/analytics/linee/${cid}/${vid}/prods${selectedProds.join('-')}/${startPeriod.format("YYYY-MM-DD")}/${endPeriod.format("YYYY-MM-DD")}/${flgPeriodContract}`)
        .then((res) => {
          setData(res);
        })
        .catch(() => {
          push({ title: "Errore durante il caricamento", type: "error" });
        });
    };

    fetchData();

  }, [cid, vid, startPeriod, endPeriod, selectedProds, push, flgPeriodContract]);

  return (
    <>
      <ErrorBoundary from="visite">
        {(!data || !products) &&
          <>
            <div className="m-10"><Placeholder height="h-32" classNames="rounded mb-3"/></div>
            <div className="m-10"><Placeholder height="h-32" classNames="rounded mb-3"/></div>
          </>
        }
        {data && products &&
          <div className="flex">
            <CheckboxGroup
              horizontal
              options={products}
              values={selectedProds}
              onChange={(value) => {
                if (selectedProds.indexOf(value) >= 0) {
                  setSelectedProds([...selectedProds.filter((v) => v !== value)]);
                } else {
                  setSelectedProds([...selectedProds, value]);
                }
              }}
            />
          </div>
        }
        {(data || []).length > 0
         ? <BarChartProdotti id="bar-chart-linee" data={data} valuePerc={valuePerc} categoryField="des_linea"/>
          : <div className="text-center m-16">
            <b>Non sono presenti preventivi nel periodo selezionato</b>
          </div>
        }
      </ErrorBoundary>
    </>
  );
}

export default Linee;
