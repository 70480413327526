import React, { useCallback, useContext, useState } from "react";
import Input from "../../Input";
import Modal from "../../Modal";
import Toggle from "../../Toggle";
import Button from "../../Button";
import { NotificationsContext } from "../../Notifications";
import Placeholder from "../../Placeholder";
import RadioGroup from "../../RadioGroup";
import apiRequest from "../../../services/apiRequest";
import useRoles from "./hooks/useRoles";
import useCustomers from "./hooks/useCustomers";
import Select from "../../Select";

function AddUserDialog({ open, toggleOpen, users, setUsers }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [roleId, setRoleId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [disabled, setDisabled] = useState(false);
  const { push } = useContext(NotificationsContext);
  const roles = useRoles();
  const customers = useCustomers();


  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        if (!roleId) {
          push({
            title: "Devi selezionare un ruolo",
            type: "error",
          });
          return;
        }

        if (!customerId && roles.filter(i => i.id === roleId)[0]['name'] !== 'admin') {
          push({
            title: "Devi selezionare un titolare",
            type: "error",
          });
          return;
        }

        if (!email || !name) {
          push({
            title: "Inserisci email e nome",
            type: "error",
          });
          return;
        }

        setLoading(true);
        const data = await apiRequest.post("/users", {
          email,
          full_name: name,
          role_id: roleId,
          customer_id: customerId || customers.filter(i => i.name === 'OCS')[0].id,
          disabled,
        });
        setUsers({ ...users, [data['id']]: data });
        toggleOpen();
        push({
          title: "Utente creato con successo",
          type: "success",
        });
      } catch (error) {
        push({
          title: "Username e/o E-mail già in uso!",
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [email, name, roleId, customerId, disabled, setUsers, toggleOpen, users, push, customers, roles]
  );

  if (!open) {
    return null;
  }

  return (
    <div>
      <Modal onExit={toggleOpen}>
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            Aggiungi utente
          </h3>
        </div>

        <form id="add-user-form" onSubmit={_onSubmit}>
          <div>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              label="Nome e cognome"
              type="text"
              errorMessage="Inserisci nome e cognome"
              required
            />
          </div>
          <div>
            <Input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              label="Email"
              type="email"
              errorMessage="Inserisci indirizzo email"
              required
            />
          </div>
          <div className="mb-4">
            {roles ? (
              <div>
                <RadioGroup
                  horizontal
                  title="Ruolo"
                  options={roles.map((role) => ({
                    value: role.id,
                    name:
                      role.name.charAt(0).toUpperCase() + role.name.slice(1),
                  }))}
                  currentValue={roleId}
                  onChange={(value) => {
                    setRoleId(value);
                  }}
                />
              </div>
            ) : (
              <div>
                <Placeholder width="w-4/5" />
                <Placeholder width="w-3/5" />
              </div>
            )}
          </div>
          <div className="mb-4">
            {customers ? (
              <div>
                {roleId && roles.filter(i => i.id === roleId)[0]['name'] !== 'admin' &&
                <Select
                  options={customers.map((i) => ({
                    value: i.id,
                    name: i.name
                  }))}
                  value={customerId}
                  onChange={(e) => setCustomerId(e.target.value)}
                  label="Punto vendita"
                />
                }
              </div>
            ) : (
              <div>
                <Placeholder width="w-4/5" />
                <Placeholder width="w-3/5" />
              </div>
            )}
          </div>

          <div className="mb-6">
            <Toggle
              vertical
              label="Utente disabilitato"
              checked={disabled}
              onChange={() => setDisabled(!disabled)}
            />
          </div>
          <div className="mt-4 flex items-end justify-end">
            <Button submitting={loading} type="submit">
              AGGIUNGI
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddUserDialog;
