import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {getTooltip, getXYChart, addCursor, getRenderAxisX, getRenderAxisY, addSeriesBullet} from "./commons";
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT";


function AreaPreventivi({id, data}) {

  useLayoutEffect(() => {

    am5.addLicense("AM5C328990290")
    let root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)])
    root.dateFormatter.set("dateFormat", "MMM yyyy")
    root.locale = am5locales_it_IT;

    // Create chart
    let chart = getXYChart(root, {layout: root.verticalLayout})
    addCursor(root, chart)

    let legend = chart.rightAxesContainer.children.push(
      am5.Legend.new(root, {
        centerY: am5.p50,
        y: am5.p50,
      })
    );

    // Create axes
    let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      baseInterval: {timeUnit: "month", count: 1},
      renderer: getRenderAxisX(root, false)
    }))
    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxPrecision: 0,
      renderer: getRenderAxisY(root)
    }))

    // Add series
    function createSeries(field, name, color) {
      let series = chart.series.push(am5xy.LineSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        stacked: true,
        valueYField: field,
        valueXField: "mese",
        fill: color,
        stroke: color,
        tooltip: getTooltip(root, "[bold]{name}[/] \n {mese.formatDate()}: {valueY}")
      }))

      series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true
      });

      // Add bullet
      addSeriesBullet(root, series, 3, 0)

      series.data.processor = am5.DataProcessor.new(root, {
        numericFields: [field],
        dateFields: ['mese'],
        dateFormat: "yyyy-MM-dd"
      })
      series.data.setAll([...data].reverse());
      series.appear(1000);

      legend.data.push(series);
    }


    createSeries('prev_persi', 'Persi', am5.color("rgb(224,103,83)"))
    createSeries('prev_aperti', 'Aperti', am5.color("rgb(0, 60, 80)"))
    createSeries('prev_chiusi', 'Chiusi', am5.color(0x5aaa95))

    return () => {
      root.dispose()
    }
  }, [data, id]) // eslint-disable-line react-hooks/exhaustive-deps

  return <div id={id} style={{minWidth: "800px", minHeight: "350px"}}/>
}

export default React.memo(AreaPreventivi, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
