import React, { useState, useEffect, useContext } from "react";

// Services
import { NotificationsContext} from "../../ui-components/Notifications";
import ErrorBoundary from "../../ui-components/ErrorBoundary";
import apiRequest from "../../services/apiRequest";
import moment from "moment";

// UI
import {MESI} from "../../utils/constants";
import Button from "../../ui-components/Button";
import {PlusCircleIcon, MinusCircleIcon} from "@heroicons/react/solid";
import Placeholder from "../../ui-components/Placeholder";


function ContatoreVisite({cid, contatore, setContatore}) {


  const [lastUpdate, setLastUpdate] = useState('-');
  const [loading, setLoading] = useState(false);
  const { push } = useContext(NotificationsContext);

  const currentMonth = contatore && MESI[moment(contatore[0]['mese']).month()]
  const currentYear = contatore && moment(contatore[0]['mese']).year()

  useEffect(() => {
    if (contatore && contatore[0]['dat_aggiornamento']) {
      setLastUpdate(moment(contatore[0]['dat_aggiornamento']).format("DD/MM/YYYY"))
    }
  }, [contatore])

  useEffect(() => {
    const fetchData = () => {
      apiRequest
        .get(`/analytics/visit/${cid}`)
        .then((res) => {
          setContatore(res);
        })
        .catch(() => {
          push({ title: "Errore durante il caricamento", type: "error" });
        });
    };

    fetchData();

  }, [cid]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateCounter = (value) => {
    setLoading(true)
    apiRequest.get(`analytics/visit/update/?cid=${cid}&mese=${moment(contatore[0]['mese']).format('YYYY-MM-01')}&value=${value}`)
      .then(() => {
        let newRow = [...contatore]
        newRow[0].num_visite = newRow[0].num_visite + value
        newRow[0].dat_aggiornamento = new Date()
        setContatore(newRow)
      })
      .catch(() => console.log('error'))
      .finally(setLoading(false))
  }


  return (
    <>
      <ErrorBoundary from="visite">
        <div className="md:col-span-3 mt-2">
        {contatore
          ? <div className="mt-3 px-2 py-2 bg-white shadow rounded-lg overflow-hidden sm:p-3 sm:w-64 sm:mr-24">
            <div className="grid grid-cols-4 gap-2">
              <div className="flex flex-col text-center col-span-3">
                <dt className="text-xl font-medium text-am-600">{currentMonth} {currentYear}</dt>
                <dt className="text-xs font-medium text-gray-500">visite nel mese</dt>
                <dd className="m-1 text-4xl font-semibold text-am-600">{contatore[0]['num_visite']}</dd>
                <dt className="text-xxs text-gray-500">ultimo aggiornamento: <b>{lastUpdate}</b></dt>
              </div>
              <div className="mt-3">
                <Button
                  disabled={loading}
                  styleType="transparent"
                  onClick={() => updateCounter(1)}
                >
                  <PlusCircleIcon className="h-6 w-6 text-gray-500 mr-2 hover:text-am-700"/>
                </Button>
                <Button
                  disabled={loading}
                  styleType="transparent"
                  onClick={() => updateCounter(-1)}
                >
                  <MinusCircleIcon className="h-6 w-6 text-gray-500 mr-2 hover:text-am-700"/>
                </Button>
              </div>
            </div>
          </div>
          : <div className="m-10">
            <Placeholder height="h-32" width="w-64" classNames="rounded mb-3"/>
          </div>
        }
        </div>
      </ErrorBoundary>
    </>
  );
}

export default ContatoreVisite;
