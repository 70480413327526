import React from "react";

// UI
import MaterialTable from "material-table";
import _ from "lodash";

// Table Params
import { getColumns, OPTIONS, LOCALIZATION } from "./Params";
import { tableIcons } from "../../TableIcons";
import AuthenticationService from "../../../services/AuthenticationService";
import apiRequest from "../../../services/apiRequest";
import useRoles from "./hooks/useRoles";
import useCustomers from "./hooks/useCustomers";

function UsersTable({ users, setUsers, push }) {
  const scopes = AuthenticationService.getUserScopes();
  const roles = useRoles();
  const customers = useCustomers();

  let _columns;
  if (roles && customers) {
    _columns = getColumns(roles, customers)
  }

  const _users = users && _.values(users).map((user) => ({ ...user }));

  function manageUpdatedUser(oldData, newData) {
    const { [oldData.id]: tmp, ...rest } = users;
    let updatedUser = newData
    if (newData.role_id !== newData.role.id) {
      updatedUser.role = roles.find((r) => r.id === parseInt(newData.role_id))
    }
    if (newData.customer_id !== newData.customer.id) {
      updatedUser.customer = customers.find((c) => c.id === parseInt(newData.customer_id))
    }

    setUsers({ ...rest, [newData.id]: updatedUser });
  }

  const _onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      let isEqual = true;
      Object.entries(newData).forEach(([key, value]) => {
        if (oldData[key] !== value) {
          isEqual = false;
        }
      });
      setImmediate(() => {
        if (isEqual) {
          resolve();
        } else {
          apiRequest
            .post(`/users/${oldData.id}`, newData)
            .then(() => {
              manageUpdatedUser(oldData, newData);
              push({
                title: "Utenza aggiornata con successo",
                type: "success",
              });
              resolve();
            })
            .catch((err) => {
              console.error("Error during user update!", err);
              push({ title: "Impossibile aggiornare l'utenza", type: "error" });
              reject();
            });
        }
      });
    });

  const _onRowDelete = (newData) =>
    new Promise((resolve, reject) => {
      setImmediate(() => {
        apiRequest
          .delete(`/users/${encodeURIComponent(newData.id)}`)
          .then(() => {
            const { [newData.id]: tmp, ...rest } = users;
            setUsers({ ...rest });
            push({ title: "Utenza eliminata con successo", type: "success" });
            resolve();
          })
          .catch((err) => {
            console.error("Error during user delete!", err);
            push({ title: "Impossibile eliminare l'utenza", type: "error" });
            reject();
          });
      });
    });

  return (
    <MaterialTable
      columns={_columns}
      data={_users}
      icons={tableIcons}
      isLoading={_users === undefined}
      options={OPTIONS}
      localization={LOCALIZATION}
      editable={{
        ...(scopes.indexOf("user:update") >= 0
          ? { onRowUpdate: _onRowUpdate }
          : {}),
        ...(scopes.indexOf("user:delete") >= 0
          ? { onRowDelete: _onRowDelete }
          : {}),
      }}
    />
  );
}

export default React.memo(UsersTable, (props, nextProps) => {
  // Render component only when users change
  return _.isEqual(props.users, nextProps.users);
});
