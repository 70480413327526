import React from "react";

import apiRequest from "../../services/apiRequest";

// UI
import MaterialTable from "material-table";
import _ from "lodash";

// Table Params
import { COLUMNS, OPTIONS, LOCALIZATION } from "./Params/Customer";
import { tableIcons } from "../../ui-components/TableIcons";


function CustomerTable({ customers, setCustomers, push }) {

  const _onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      let isEqual = true;
      Object.entries(newData).forEach(([key, value]) => {
        if (oldData[key] !== value) {
          isEqual = false;
        }
      });
      setImmediate(() => {
        if (isEqual) {
          resolve();
        } else {
          apiRequest.post(`customers/${oldData.id}`, newData)
            .then((res) => {
              setCustomers({...customers, [res['id']]: res})
              push({title: "Elemento aggiornato con successo", type: "success"});
              resolve();
            })
            .catch(() => {
              push({title: "Errore imprevisto", type: "error"});
              reject();
            });
        }
      });
    });

  const _onRowDelete = (newData) =>
    new Promise((resolve, reject) => {
      setImmediate(() => {
        apiRequest.delete(`customers/${encodeURIComponent(newData.id)}`, newData)
          .then(() => {
            const { [newData.id]: tmp, ...rest } = customers;
            setCustomers({ ...rest });
            push({title: "Elemento eliminato", type: "success"});
            resolve();
          })
          .catch(() => {
            push({title: "Impossibile eliminare l'elemento", type: "error"});
            reject();
          });
      });
    });

  return (
    <>
      <MaterialTable
        columns={COLUMNS}
        data={customers && Object.values(customers)}
        icons={tableIcons}
        isLoading={customers === undefined}
        options={OPTIONS}
        title={"Prodotti"}
        localization={LOCALIZATION}
        editable={{
          onRowUpdate: _onRowUpdate,
          onRowDelete: _onRowDelete
      }}
      />
    </>
  );
}

export default React.memo(CustomerTable, (props, nextProps) => {
  return _.isEqual(props.customers, nextProps.customers);
});
