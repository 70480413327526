import React, { useState, useEffect, useContext } from "react";

// Services
import { NotificationsContext} from "../../ui-components/Notifications";
import ErrorBoundary from "../../ui-components/ErrorBoundary";
import apiRequest from "../../services/apiRequest";
import Placeholder from "../../ui-components/Placeholder";
import BasicGauge from "../../components/Charts/BasicGauge";

// UI



function Totale({cid, vid, startPeriod, endPeriod, flgPeriodContract}) {
  const [data, setData] = useState();
  const { push } = useContext(NotificationsContext);

  useEffect(() => {
    const fetchData = () => {
      apiRequest
        .get(`/analytics/totale/${cid}/${vid}/${startPeriod.format("YYYY-MM-DD")}/${endPeriod.format("YYYY-MM-DD")}/${flgPeriodContract}`)
        .then((res) => {
          setData(res);
        })
        .catch(() => {
          push({ title: "Errore durante il caricamento", type: "error" });
        });
    };

    fetchData();

  }, [cid, vid, push, startPeriod, endPeriod, flgPeriodContract]);

  return (
    <>
      <ErrorBoundary from="totale">
        {data
          ?
          <div className="mb-8 px-2 pt-2 shadow rounded-lg overflow-hidden grid sm:grid-cols-2 sm:gap-40">
            <div className="grid grid-cols-3 gap-2">
              <div className="flex flex-col col-span-3">
                <dt className="ml-5 text-lg font-semibold text-gray-500">Totale</dt>
              </div>
              <div className="flex flex-col col-span-2">
                <dt className="m-1 ml-5 text-sm font-semibold text-am-600">Preventivi</dt>
                <dt className="m-1 ml-5 text-sm font-semibold text-contract-600">Contratti</dt>
              </div>
              <div>
                <dt className=" text-lg font-semibold text-am-600">{data['preventivi']}</dt>
                <dt className="text-lg font-semibold text-contract-600">{data['contratti']}</dt>
              </div>
              {flgPeriodContract &&
                <div className="flex flex-col col-span-2">
                  <BasicGauge
                    id="gauge-totale"
                    data={Number((data['contratti'] / (data['preventivi'] + 0.0001) * 100).toFixed(0))}
                  />
                </div>
              }
              <div className="flex flex-col col-span-2">
                <dt className="m-1 ml-5 mb-8 text-sm font-semibold text-gray-600">Media giorni chiusura</dt>
              </div>
              <div>
                <dt className="text-lg font-semibold text-gray-600">{data['giorni_chiusura'] || '-'}</dt>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-2">
              <div className="flex flex-col col-span-3">
                <dt className="ml-5 text-lg font-semibold text-gray-500">Con Virtuo</dt>
              </div>
              <div className="flex flex-col col-span-2">
                <dt className="m-1 ml-5 text-sm font-semibold text-am-600">Preventivi</dt>
                <dt className="m-1 ml-5 text-sm font-semibold text-contract-600">Contratti</dt>
              </div>
              <div>
                <dt>
                  <dt className="text-lg font-semibold text-am-600 inline-block">{data['preventivi_virtuo']}</dt>
                  <dt className="ml-2 text-xs text-am-600 inline-block">({(data['preventivi_virtuo'] / (data['preventivi'] + 0.0001) * 100).toFixed(1)}%)</dt>
                </dt>
                <dt>
                  <dt className="text-lg font-semibold text-contract-600 inline-block">{data['contratti_virtuo']}</dt>
                  <dt className="ml-2 text-xs text-contract-600 inline-block">({(data['contratti_virtuo'] / (data['contratti'] + 0.0001) * 100).toFixed(1)}%)</dt>
                </dt>
              </div>
              {flgPeriodContract &&
                <div className="flex flex-col col-span-2">
                  <BasicGauge
                    id="gauge-virtuo"
                    data={Number((data['contratti_virtuo'] / (data['preventivi_virtuo'] + 0.0001) * 100).toFixed(0))}
                  />
                </div>
              }
              <div className="flex flex-col col-span-2">
                <dt className="m-1 ml-5 mb-8 text-sm font-semibold text-gray-600">Media giorni chiusura</dt>
              </div>
              <div>
                <dt className="text-lg font-semibold text-gray-600">{data['giorni_chiusura_virtuo'] || '-'}</dt>
              </div>
            </div>
          </div>
          :
          <div className="m-10">
            <Placeholder height="h-32" classNames="rounded mb-3"/>
          </div>
        }
      </ErrorBoundary>
    </>
  );
}

export default Totale;
