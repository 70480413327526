import axios from "axios";
import { API_URL } from "../utils/constants";
import AuthenticationService from "./AuthenticationService";

const apiRequest = axios.create({
  baseURL: API_URL,
});

apiRequest.defaults.headers.common["Content-Type"] = "application/json";

apiRequest.interceptors.request.use(
  (config) => {
    const { publicRequest } = config;

    if (publicRequest) {
      return config;
    }

    const { access_token } = AuthenticationService.getUserData();

    if (!access_token) {
      throw new Error("user not logged");
    }

    return {
      ...config,
      headers: { ...config.headers, Authorization: `Bearer ${access_token}` },
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiRequest.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiRequest;
