import React, { Fragment, useCallback, useState } from "react";
import Input from "../Input";

const UpdatePassword = ({
  setPassword,
  setConfirmPassword,
  password,
  confirmPassword,
  showPassword,
}) => {
  const [confirmError, setConfirmError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handlePassword = useCallback((value) => {
    if (value.length < 8) {
      setPasswordError("La password deve essere lunga almeno 8 caratteri");
      return false;
    } else if (value.includes(" ")) {
      setPasswordError("La password non deve contenere spazi");
      return false;
    }

    setPasswordError();
    return true;
  }, []);

  const handleConfirmPassword = useCallback(
    (value) => {
      if (value !== password) {
        setConfirmError("Le due password non coincidono");
        return false;
      }
      setConfirmError();
      return true;
    },
    [password]
  );

  return (
    <Fragment>
      <div>
        <Input
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          label="Password"
          type={showPassword ? "text" : "password"}
          validation={handlePassword}
          errorMessage={passwordError}
          required
        />
      </div>
      <div>
        <Input
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
          validation={handleConfirmPassword}
          label="Conferma password"
          type={showPassword ? "text" : "password"}
          errorMessage={confirmError}
          required
        />
      </div>
    </Fragment>
  );
};

export default UpdatePassword;
