import React, { useCallback, useContext, useState } from "react";

// Services
import apiRequest from "../../services/apiRequest";

import Input from "../../ui-components/Input";
import Modal from "../../ui-components/Modal";
import Button from "../../ui-components/Button";
import Select from "../../ui-components/Select";
import { NotificationsContext } from "../../ui-components/Notifications";

function AddModelDialog({ open, toggleOpen, model, setModel, prod, line }) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [prodId, setProdId] = useState(Object.values(prod)[0].id);
  const [lineId, setLineId] = useState(Object.values(line)[0].id);
  const { push } = useContext(NotificationsContext);


  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        if (!name) {
          push({
            title: "Dati mancanti",
            type: "error",
          });
          return;
        }

        setLoading(true);
        const data = await apiRequest.post("conf/model", {
          des_modello: name,
          prod_id: prodId,
          line_id: lineId
        });
        setModel({ ...model, [data['id']]: data });
        toggleOpen();
        push({
          title: "Modello creato con successo",
          type: "success",
        });
      } catch (error) {
        push({
          title: "Errore imprevisto",
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [name, model] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (!open) {
    return null;
  }

  return (
    <div>
      <Modal onExit={toggleOpen}>
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            Aggiungi modello
          </h3>
        </div>

        <form id="add-user-form" onSubmit={_onSubmit}>
          <div>
            <Select
              options={Object.values(prod).map((i) => ({
                value: i.id,
                name: i.des_prodotto
              }))}
              value={prodId}
              onChange={(e) => setProdId(e.target.value)}
              label="Prodotto"
            />
          </div>
          <div className="mt-4 mb-4">
            <Select
              options={Object.values(line).map((i) => ({
                value: i.id,
                name: i.des_linea
              }))}
              value={lineId}
              onChange={(e) => setLineId(e.target.value)}
              label="Linea"
            />
          </div>
          <div>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              label="Nome modello"
              type="text"
              errorMessage="Inserisci il nome del modello"
              required
            />
          </div>
          <div className="mt-4 flex items-end justify-end">
            <Button submitting={loading} type="submit">
              Aggiungi
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddModelDialog;
