import React from "react";


// UI
import MaterialTable from "material-table";
import _ from "lodash";
import moment from "moment";

// Table Params
import { getColumns, OPTIONS, LOCALIZATION } from "./Params/Preventivi";
import { tableIcons } from "../../ui-components/TableIcons";
import {MinusSmIcon} from "@heroicons/react/solid";

function PreventiviTable({ data, filterCid, setToUpdate, setView, conf, canSeeCosts, canReadAll }) {

  const _data = data ? filterCid ? Object.values(data).filter(x => x['cod_titolare'] === Number(filterCid)) : Object.values(data) : undefined

  const titolari = _data && [...new Set(_data.map(i => i['titolare']))].sort().reduce((o, key) => ({...o, [key]: key}), {})
  const venditori = _data && [...new Set(_data.map(i => i['venditore']))].sort().reduce((o, key) => ({...o, [key]: key}), {})

  const chiusura = (rowData) => <><b><small>Chiusura / Ricontatto:</small></b> {rowData['dat_chiusura'] ? moment(rowData.dat_chiusura).format('DD/MM/YYYY') : <MinusSmIcon className="inline-block h-4 w-4 text-gray-400 group-hover:text-gray-500"/>}<br/></>
  const giorni = (rowData) => <><b><small>Giorni dall'emissione:</small></b> {rowData['dat_chiusura'] ? moment(rowData['dat_chiusura']).diff(moment(rowData['dat_emissione']), 'days') : <MinusSmIcon className="inline-block h-4 w-4 text-gray-400 group-hover:text-gray-500"/>}<br/></>
  const listino = (rowData) => <><b><small>Listino ivato:</small></b> € {Number(rowData['amt_listino_iva']).toLocaleString('it-IT')}<br/></>
  const vendita = (rowData) => <><b><small>Vendita ivato:</small></b> € {Number(rowData['amt_vendita_iva']).toLocaleString('it-IT')}<br/></>
  const acquisto = (rowData) => <><b><small>Acquisto iva esc:</small></b> € {Number(rowData['amt_acquisto']).toLocaleString('it-IT')}<br/></>
  const margine = (rowData) => <><b><small>Margine iva esc:</small></b> € {Number(rowData['amt_margine']).toLocaleString('it-IT')} ({rowData['val_margine']}%)<br/></>
  const oneri = (rowData) => <><b><small>Oneri consegna:</small></b> € {Number(rowData['amt_oneri_consegna']).toLocaleString('it-IT')}<br/></>
  const sconto = (rowData) => <><b><small>Sconto:</small></b> <i>{rowData['val_sconto']}%</i><br/></>
  const ricarico = (rowData) => <><b><small>Ricarico:</small></b> <i>{rowData['val_ricarico']}%</i><br/></>

  const Detail = (rowData) => {
    return <>
      <div className="mb-4">
      {canSeeCosts
      ? <div className="grid m-4 gap-4 grid-cols-5">
          <div>{chiusura(rowData)}{giorni(rowData)}</div>
          <div>{listino(rowData)}{vendita(rowData)}{sconto(rowData)}</div>
          <div>{acquisto(rowData)}{margine(rowData)}{ricarico(rowData)}</div>
          <div>{oneri(rowData)}</div>
        </div>
      : <div className="grid m-4 gap-4 grid-cols-5">
          <div>{chiusura(rowData)}</div>
          <div>{giorni(rowData)}</div>
          <div>{listino(rowData)}</div>
          <div>{vendita(rowData)}</div>
        </div>
      }
      {rowData['note'] && <div className="ml-4"><small><b>Note:</b> <i>{rowData['note']}</i></small></div>}
      </div>
    </>
  }
  return (
    <>
      <MaterialTable
        columns={getColumns(conf, canSeeCosts, canReadAll, titolari, venditori)}
        data={_data}
        icons={tableIcons}
        isLoading={data === undefined}
        options={OPTIONS}
        localization={LOCALIZATION}
        detailPanel={rowData => Detail(rowData)}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        actions={[{
          icon: tableIcons.Edit,
          tooltip: "Modifica preventivo",
          onClick: (event, rowData) => {
            setToUpdate(rowData)
            setView('add')
          }
        }]}
      />
    </>
  );
}

export default React.memo(PreventiviTable, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data) && _.isEqual(props.filterCid, nextProps.filterCid);
});
