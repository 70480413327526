import React from "react";
import Select from "../../../ui-components/Select";
import Spinner from "../../../ui-components/Spinner";

const SelectProd = ({ value, onChange, prod }) => {
  if (!prod) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }
  return (
    <Select
      options={Object.values(prod).map((p) => ({ value: p.id, name: p.des_prodotto }))}
      value={value}
      onChange={(e) => {onChange(e.target.value)}}
    />
  );
};

const SelectLine = ({ value, onChange, line }) => {
  if (!line) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }
  return (
    <Select
      options={Object.values(line).map((p) => ({ value: p.id, name: p.des_linea }))}
      value={value}
      onChange={(e) => {onChange(e.target.value)}}
    />
  );
};


export const getColumns = (prod, line) => [
  {
    title: "Prodotto",
    field: "prod_id",
    render: ({ prod }) => <span className="capitalize">{prod.des_prodotto}</span>,
    editComponent: (props) => <SelectProd {...props} prod={prod} />,
  },
  {
    title: "Linea",
    field: "line_id",
    render: ({ line }) => <span className="capitalize">{line.des_linea}</span>,
    editComponent: (props) => <SelectLine {...props} line={line} />,
  },
  {
    title: "Modello",
    field: "des_modello"
  }
]

export const OPTIONS = {
  filtering: false,
  search: false,
  showTitle: true,
  toolbar: true,
  paging: false,
  sorting: true,
  headerStyle: {fontWeight: "bold", fontSize: 14, padding: 12},
  cellStyle: {padding: 12, whiteSpace: "nowrap", minWidth: 200}
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  body: {
    editRow: {
      deleteText: "Eliminare il modello?",
    },
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} righe",
    labelRowsSelect: "Righe",
  }
};
