import React, { useState, useEffect, useContext } from "react";

// Services
import { NotificationsContext} from "../../ui-components/Notifications";
import ErrorBoundary from "../../ui-components/ErrorBoundary";
import apiRequest from "../../services/apiRequest";

// UI
import Placeholder from "../../ui-components/Placeholder";
import RadioGroup from "../../ui-components/RadioGroup";
import BarChartSummary from "../../components/Charts/BarChartSummary";


function SummaryKpi({cid, contatore}) {
  const [data, setData] = useState();
  const [chartData, setChartData] = useState();
  const [period, setPeriod] = useState('mtd');
  const { push } = useContext(NotificationsContext);

  useEffect(() => {
    if (data) {
      const filtered = (data || []).filter(x => x.period === period)[0]
      const vis = filtered['num_visite']
      const prev = filtered['num_preventivi']
      const cont = filtered['num_contratti']
      setChartData([
      {category: 'Visite', measure: vis, label: vis},
      {category: 'Preventivi', measure: prev, label: prev},
      {category: 'Contratti', measure: cont, label: cont},
    ])
    }
  }, [data, period])

  useEffect(() => {
    const fetchData = () => {
      apiRequest
        .get(`/analytics/summary/${cid}`)
        .then((res) => {
          setData(res);
        })
        .catch(() => {
          push({ title: "Errore durante il caricamento", type: "error" });
        });
    };

    fetchData();

  }, [cid, contatore, push]);


  return (
    <>
      <ErrorBoundary from="visite">
        <div className="md:col-span-3">
        {data
          ? <div>
            <div className="flex w-full justify-center">
              <RadioGroup
                id="period"
                horizontal
                options={[
                  { value: "mtd", name: "Mese corrente" },
                  { value: "ytd", name: "Anno corrente" }
                ]}
                currentValue={period}
                onChange={(value) => {setPeriod(value)}}
              />
            </div>
            {chartData && <BarChartSummary id="bar-chart-summary" data={chartData}/> }
          </div>
          : <div className="m-10">
            <Placeholder height="h-32" width="w-64" classNames="rounded mb-3"/>
          </div>
        }
        </div>
      </ErrorBoundary>
    </>
  );
}

export default SummaryKpi;
