import React, {useContext, useState} from "react";

import {NotificationsContext} from "../../ui-components/Notifications";
import apiRequest from "../../services/apiRequest";

// UI
import AddModelDialog from "../Dialogs/AddModelDialog";
import MaterialTable from "material-table";
import _ from "lodash";

// Table Params
import { getColumns, OPTIONS, LOCALIZATION } from "./Params/Modelli";
import { tableIcons } from "../../ui-components/TableIcons";


function ModelTable({ model, setModel, prod, line }) {

  const { push } = useContext(NotificationsContext);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const toggleDialog = () => setShowAddDialog(!showAddDialog);

  const _onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      let isEqual = true;
      Object.entries(newData).forEach(([key, value]) => {
        if (oldData[key] !== value) {
          isEqual = false;
        }
      });
      setImmediate(() => {
        if (isEqual) {
          resolve();
        } else {
          apiRequest.post(`conf/model/${oldData.id}`, newData)
            .then((res) => {
              setModel({...model, [res['id']]: res})
              push({title: "Modello aggiornato con successo", type: "success"});
              resolve();
            })
            .catch(() => {
              push({title: "Errore imprevisto", type: "error"});
              reject();
            });
        }
      });
    });

  const _onRowDelete = (newData) =>
    new Promise((resolve, reject) => {
      setImmediate(() => {
        apiRequest.delete(`conf/model/${encodeURIComponent(newData.id)}`, newData)
          .then(() => {
            const { [newData.id]: tmp, ...rest } = model;
            setModel({ ...rest });
            push({title: "Modello eliminato", type: "success"});
            resolve();
          })
          .catch(() => {
            push({title: "Impossibile eliminare l'elemento", type: "error"});
            reject();
          });
      });
    });

  return (
    <>
      <MaterialTable
        columns={getColumns(prod, line)}
        data={model && Object.values(model)}
        icons={tableIcons}
        isLoading={model === undefined}
        options={OPTIONS}
        title={"Modelli"}
        localization={LOCALIZATION}
        actions={[{
          icon: tableIcons.Add,
          tooltip: "Aggiungi modello",
          onClick: () => {toggleDialog()},
          isFreeAction: true,
        }]}
        editable={{
          onRowUpdate: _onRowUpdate,
          onRowDelete: _onRowDelete
      }}
      />
      {showAddDialog &&
      <AddModelDialog
        open={showAddDialog}
        toggleOpen={toggleDialog}
        model={model}
        setModel={setModel}
        prod={prod}
        line={line}
      />
      }
    </>
  );
}

export default React.memo(ModelTable, (props, nextProps) => {
  return _.isEqual(props.model, nextProps.model);
});
