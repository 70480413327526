import { useContext, useEffect, useState } from "react";
import apiRequest from "../../../../services/apiRequest";
import { NotificationsContext } from "../../../Notifications";

const usePolicies = () => {
  const { push } = useContext(NotificationsContext);
  const [policies, setPolicies] = useState(null);

  useEffect(() => {
    let closed = false;
    const fetchPolicies = async () => {
      try {
        const data = await apiRequest.get("/policies");

        if (typeof data !== "object") {
          throw new Error("not object");
        }

        if (!closed) {
          setPolicies(data);
        }
      } catch (error) {
        if (!closed) {
          push({
            title: "Errore durante il caricamento",
            type: "error",
          });
        }
      }
    };

    fetchPolicies();

    return () => {
      closed = true;
    };
  }, [push]);

  return policies;
};

export default usePolicies;
