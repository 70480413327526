import React from "react";
import Spinner from "../../Spinner";
import SearchSelect from "../../SearchSelect";

const SelectRole = ({ value, onChange, roles }) => {

  if (!roles) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }

  const _value = roles.filter(i => i.id === value)[0]

  return (
    <div className="w-32">
      <SearchSelect
        options={roles.map((r) => ({ value: r.id, label: r.name }))}
        value={{value: _value.id, label: _value.name}}
        onChange={(e) => {onChange(e.value)}}
      />
    </div>
  );
};

export default SelectRole;
