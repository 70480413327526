import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";

// Services
import AuthenticationService from "../../services/AuthenticationService";
import { NotificationsContext} from "../../ui-components/Notifications";
import apiRequest from "../../services/apiRequest";
import ErrorBoundary from "../../ui-components/ErrorBoundary";

// Components
import AddCustomerDialog from "./AddCustomerDialog";
import CustomerTable from "../../components/Tables/CustomerTable";

// UI
import { UserAddIcon } from "@heroicons/react/solid";
import { PageHeading} from "../../ui-components/Container";
import Button from "../../ui-components/Button";

function Customers() {
  const [customers, setCustomers] = useState();
  const [showNewUserDialog, setShowNewUserDialog] = useState(false);
  const scopes = AuthenticationService.getUserScopes();
  const { push } = useContext(NotificationsContext);

  useEffect(() => {
    const fetchCustomers = () => {
      apiRequest
        .get("/customers")
        .then((customers) => {
          setCustomers(
            (customers || []).reduce(
              (customersObj, customer) => _.set(customersObj, [customer.id], customer),
              {}
            )
          );
        })
        .catch(() => {
          push({ title: "Errore durante il caricamento", type: "error" });
        });
    };

    if (!customers) {
      fetchCustomers();
    }
  }, [customers, push]);

  const toggleNewUserDialog = () => setShowNewUserDialog(!showNewUserDialog);
  const canCreateUser = scopes.indexOf("user:create") >= 0;

  return (
    <>
      <PageHeading
        title="Gestione clienti"
        contentActions={
          canCreateUser ? (
            <Button
              variant="contained"
              color="primary"
              onClick={toggleNewUserDialog}
            >
              <UserAddIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              Aggiungi
            </Button>
          ) : null
        }
      />

      <ErrorBoundary from="customers-table">
        <CustomerTable customers={customers} setCustomers={setCustomers} push={push} />
      </ErrorBoundary>
      {canCreateUser ? (
        <ErrorBoundary from="customers-add-dialog">
          <AddCustomerDialog
            open={showNewUserDialog}
            toggleOpen={toggleNewUserDialog}
            customers={customers}
            setCustomers={setCustomers}
            push={push}
          />
        </ErrorBoundary>
      ) : null}
    </>
  );
}

export default Customers;
