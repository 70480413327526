import React, {useContext, useEffect, useState} from "react";

import AuthenticationService from "../../services/AuthenticationService";
import {NotificationsContext} from "../../ui-components/Notifications";
import {DataContext} from "../../services/DataContext";
import apiRequest from "../../services/apiRequest";

// UI
import { PageHeading, PageSection } from "../../ui-components/Container";
import DatePicker from "../../ui-components/DatePicker";
import RadioGroup from "../../ui-components/RadioGroup";
import Select from "../../ui-components/Select";
import Toggle from "../../ui-components/Toggle";
import Tabs from "../../ui-components/Tabs";
import {TABS} from "../../utils/constants";
import moment from "moment";

import Totale from "./totale";
import Visite from "./visite";
import Preventivi from "./preventivi";
import Prodotti from "./prodotti";
import Linee from "./linee";
import Modelli from "./modelli";
import Valore from "./valore";
import Canale from "./canale";
import Margini from "./margini";


function Analytics() {

  const dataContext = useContext(DataContext);
  const user = AuthenticationService.getUserData();
  const scopes = AuthenticationService.getUserScopes();
  const [tabIndex, setTabIndex] = useState(0);
  const { push } = useContext(NotificationsContext);
  const [visitData, setVisitData] = useState();
  const [products, setProducts] = useState();
  const isAdmin = scopes.indexOf("conf:read") >= 0;
  const isTitolare = scopes.indexOf("prev:costs") >= 0;

  const otherIds = (user['other_customer_ids'] || "").split(";").filter(i => i !== '')
  const isTitolareWithMultipleViews = isTitolare && !isAdmin && otherIds.length > 0

  const [cid, setCid] = useState(user.customer_id);
  const [vid, setVid] = useState(isTitolare ? 0 : user.id);

  const [listaVenditori, setListaVenditori] = useState([{value: 0, name: "Tutti i venditori"}]);

  // Prodotti
  const [startPeriod, setStartPeriod] = useState(moment().startOf('year'));
  const [endPeriod, setEndPeriod] = useState(moment());
  const [flgPeriodContract, setFlgPeriodContract] = useState(false);
  const [valuePerc, setValuePerc] = useState('value');

  useEffect(() => {
    if ((isAdmin && dataContext.customers) || dataContext.venditori) {
      if (isAdmin || isTitolareWithMultipleViews) {
        if (Number(cid) === 1) {
          setVid(0)
          setListaVenditori([{value: 0, name: "Tutti i venditori"}])
        } else {
          setListaVenditori([{value: 0, name: "Tutti i venditori"}].concat(
            dataContext.venditori.filter(x => x['customer_id'] === Number(cid))
          ))
        }
      } else {
        setListaVenditori([{value: 0, name: "Tutti i venditori"}].concat(dataContext.venditori))
      }
    }
  }, [dataContext.venditori, cid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchData = () => {
      apiRequest
        .get(`/analytics/visit/${cid}`)
        .then((res) => {
          setVisitData(res);
        })
        .catch(() => {
          push({ title: "Errore durante il caricamento", type: "error" });
        });
    };

    const fetchProducts = () => {
      apiRequest.get("conf/prod")
        .then((res) => setProducts((res || []).map((p) => ({ value: p.id, name: p.des_prodotto }))))
        .catch(() => {console.error("Error when fetching products")})
    }

    const fetchCustomers = () => {
      const url = isTitolareWithMultipleViews ? "customers/multiple_customers" : "customers"
      apiRequest.get(url)
        .then((res) => {
          dataContext.setCustomers((res || []).map(i => ({value: i['id'], name: i['name']})))
        })
        .catch(() => {console.error("Error when fetching customers")})
    };

    const fetchVenditori = () => {
      const id = isTitolareWithMultipleViews ? 1 : cid
      apiRequest.get(`/customers/${id}/venditori`)
        .then((res) => {
          dataContext.setVenditori((res || []).map(i => ({value: i['id'], name: i['full_name'], customer_id: i['customer_id']})))
        })
        .catch(() => {console.error("Error when fetching venditori")})
    };

    fetchData();

    if ((isAdmin || isTitolareWithMultipleViews) && !dataContext.customers) {
      fetchCustomers()
    }

    if (isTitolare && !dataContext.venditori) {
      fetchVenditori()
    }

    if (!products) {
      fetchProducts()
    }

  }, [cid, isAdmin, dataContext.customers, dataContext.venditori]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!flgPeriodContract) {
      setValuePerc('value')
    }
  }, [flgPeriodContract]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <PageHeading
        title="Analytics"
        contentActions={
        <div>
          {(isAdmin || isTitolareWithMultipleViews) && dataContext.customers &&
            <div className="inline-block mr-4">
              <Select
                options={dataContext.customers}
                value={cid}
                onChange={(e) => setCid(e.target.value)}
                label="Cliente"
              />
            </div>
          }
          {isTitolare && dataContext.venditori && listaVenditori &&
            <div className="inline-block">
              <Select
                options={listaVenditori}
                value={vid}
                onChange={(e) => setVid(e.target.value)}
                label="Venditore"
                disabled={(isAdmin || isTitolareWithMultipleViews) && Number(cid) === 1}
              />
            </div>
          }
        </div>
      }
      />
      <div className="mb-8">
        <Tabs
          setActiveTab={setTabIndex}
          active={tabIndex}
          tabs={isTitolare ? TABS.concat([{name: 'Marginalità'}]) : TABS}
        />
      </div>
      {[0, 1, 2, 3].includes(tabIndex) &&
        <div className="flex flex-col md:flex-row gap-10 mb-10">
          <DatePicker
            value={startPeriod}
            onChange={(i) => setStartPeriod(i)}
            label="Inizio Periodo"
            placeholder="Inizio Periodo"
            errorMessage="Seleziona data"
          />
          <DatePicker
            value={endPeriod}
            onChange={(i) => setEndPeriod(i)}
            label="Fine Periodo"
            placeholder="Fine Periodo"
            errorMessage="Seleziona data"
          />
          <div className="flex flex-row gap-4 px-2 mt-3">
            <div>
              <Toggle
                checked={flgPeriodContract}
                onChange={() => {setFlgPeriodContract(!flgPeriodContract)}}
              />
            </div>
            <div className="w-48">
              Considera solo i contratti aperti nel periodo
            </div>
          </div>
          {tabIndex !== 3 && tabIndex !== 0 && flgPeriodContract &&
            <div>
              <RadioGroup
                id="valuePerc"
                description="Mostra valori"
                horizontal
                options={[
                  {value: "value", name: "Valore assoluto"},
                  {value: "perc", name: "Percentuale"}
                ]}
                currentValue={valuePerc}
                onChange={(value) => {
                  setValuePerc(value)
                }}
              />
            </div>
          }
        </div>
      }
      {tabIndex === 0 &&
        <>
          <Totale cid={cid} vid={vid} startPeriod={startPeriod} endPeriod={endPeriod} flgPeriodContract={flgPeriodContract}/>
          <PageSection
            title=" "
            description="Storico visite"
            children={<Visite data={visitData}/>}
          />
          <PageSection
            title=" "
            description="Storico preventivi e contratti"
            children={<Preventivi cid={cid} vid={vid} visitData={visitData}/>}
          />
        </>
      }
      {tabIndex === 1 &&
        <>
          <PageSection
            title =" "
            description="Analisi chiusura prodotti"
            children={<Prodotti cid={cid} vid={vid} valuePerc={valuePerc} startPeriod={startPeriod} endPeriod={endPeriod} flgPeriodContract={flgPeriodContract}/>}
          />
          <PageSection
            title =" "
            description="Analisi chiusura linee"
            children={<Linee cid={cid} vid={vid} products={products} valuePerc={valuePerc} startPeriod={startPeriod} endPeriod={endPeriod} flgPeriodContract={flgPeriodContract}/>}
          />
          <PageSection
            title =" "
            description="Analisi chiusura modelli"
            children={<Modelli cid={cid} vid={vid} products={products} valuePerc={valuePerc} startPeriod={startPeriod} endPeriod={endPeriod} flgPeriodContract={flgPeriodContract}/>}
          />
        </>
      }
      {tabIndex === 2 &&
        <>
          <PageSection
            title =" "
            description="Analisi per valore di mercato"
            children={<Valore cid={cid} vid={vid} valuePerc={valuePerc} startPeriod={startPeriod} endPeriod={endPeriod} flgPeriodContract={flgPeriodContract}/>}
          />
          <PageSection
            title =" "
            description="Analisi per canale d'ingresso"
            children={<Canale cid={cid} vid={vid} valuePerc={valuePerc} startPeriod={startPeriod} endPeriod={endPeriod} flgPeriodContract={flgPeriodContract}/>}
          />
        </>
      }
      {tabIndex === 3 && isTitolare &&
        <Margini cid={cid} vid={vid} startPeriod={startPeriod} endPeriod={endPeriod} flgPeriodContract={flgPeriodContract}/>
      }
    </div>
  );
}

export default Analytics;
