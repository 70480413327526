import { useContext, useEffect, useState } from "react";
import apiRequest from "../../../../services/apiRequest";
import AuthenticationService from "../../../../services/AuthenticationService";
import { NotificationsContext } from "../../../Notifications";

const useCustomers = () => {
  const [userScopes] = useState(AuthenticationService.getUserScopes());
  const [customers, setCustomers] = useState(null);
  const { push } = useContext(NotificationsContext);

  useEffect(() => {
    let closed = false;
    const fetchCustomers = async () => {
      try {
        const data = await apiRequest.get("/customers");

        if (typeof data !== "object") {
          throw new Error("not object");
        }

        if (!closed) {
          setCustomers(data);
        }
      } catch (error) {
        if (!closed) {
          push({
            title: "Errore durante il caricamento",
            type: "error",
          });
        }
      }
    };

    if (customers === null) {
      if (userScopes.indexOf("user:read") >= 0) {
        fetchCustomers();
      } else {
        setCustomers(false);
        push({
          title: "Scope user:read non trovato",
          type: "error",
          fixed: true,
        });
      }
    }

    return () => {
      closed = true;
    };
  }, [userScopes, customers, push]);

  return customers;
};

export default useCustomers;
