import React, { useCallback, useContext, useState } from "react";

// Services
import apiRequest from "../../services/apiRequest";

import Input from "../../ui-components/Input";
import Modal from "../../ui-components/Modal";
import Button from "../../ui-components/Button";
import { NotificationsContext } from "../../ui-components/Notifications";

function AddProdDialog({ open, toggleOpen, prod, setProd }) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const { push } = useContext(NotificationsContext);


  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        if (!name) {
          push({
            title: "Dati mancanti",
            type: "error",
          });
          return;
        }

        setLoading(true);
        const data = await apiRequest.post("conf/prod", {
          des_prodotto: name
        });
        setProd({ ...prod, [data['id']]: data });
        toggleOpen();
        push({
          title: "Prodotto creato con successo",
          type: "success",
        });
      } catch (error) {
        push({
          title: "Errore imprevisto",
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [name, prod] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (!open) {
    return null;
  }

  return (
    <div>
      <Modal onExit={toggleOpen}>
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            Aggiungi prodotto
          </h3>
        </div>

        <form id="add-user-form" onSubmit={_onSubmit}>
          <div>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              label="Nome prodotto"
              type="text"
              errorMessage="Inserisci il nome del prodotto"
              required
            />
          </div>
          <div className="mt-4 flex items-end justify-end">
            <Button submitting={loading} type="submit">
              Aggiungi
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddProdDialog;
