import React from "react";
import {canaleOptions, statusOptions} from "../../../pages/Preventivi/constants";
import _ from "lodash";
import moment from "moment";
import {XCircleIcon, ShieldCheckIcon, ShieldExclamationIcon} from "@heroicons/react/outline";

const getStatusIcon = (status) => {
  switch (status) {
    case "chiuso":
      return <ShieldCheckIcon className="h-5 w-5 text-green-500 inline-block"/>;
    case "perso":
      return <XCircleIcon className="h-5 w-5 text-red-500 inline-block"/>;
    default:
      return <ShieldExclamationIcon className="h-5 w-5 text-yellow-500 inline-block"/>;
  }
}
export const getColumns = (conf, canSeeCosts, canReadAll, titolari, venditori) => [
  {
    title: "Cliente",
    field: "titolare",
    hidden: !canReadAll,
    lookup: titolari,
    cellStyle: {fontSize: 11}
  },
  {
    title: "Venditore",
    field: "venditore",
    hidden: !canSeeCosts,
    lookup: venditori,
    cellStyle: {fontSize: 11}
  },
  {
    title: "Emissione",
    field: "dat_emissione",
    render: rowData => <>{moment(rowData['dat_emissione']).format("DD/MM/YYYY")}</>,
    defaultSort: "desc",
  },
  {
    title: "Riferimento",
    field: "cliente"
  },
  {
    title: "Stato",
    field: "status",
    lookup: statusOptions.reduce((obj, i) => _.set(obj, [i.value], i.name), {}),
    render: ({status}) => <span>{getStatusIcon(status)} {status}</span>,
    cellStyle: {whiteSpace: "nowrap"}
  },
  {
    title: "Consegna",
    field: "dat_consegna",
    render: rowData => <>{moment(rowData['dat_consegna']).format("DD/MM/YYYY")}</>
  },
  {
    title: "Virtuo",
    field: "flg_virtuo",
    type: "boolean"
  },
  {
    title: "Prodotto",
    field: "cod_prodotto",
    lookup: conf['prodotti'].reduce((obj, i) => _.set(obj, [i.id], i.des_prodotto), {})
  },
  {
    title: "Modello",
    field: "cod_modello",
    lookup: conf['modelli'].reduce((obj, i) => _.set(obj, [i.id], i.des_modello), {})
  },
  {
    title: "Linea",
    field: "cod_linea_prodotto",
    lookup: conf['linee'].reduce((obj, i) => _.set(obj, [i.id], i.des_linea), {}),
    cellStyle: {fontStyle: 'italic', fontSize: 11}
  },
  {
    title: "Provenienza",
    field: "provenienza",
    cellStyle: {fontSize: 10, minWidth: 120}
  },
  {
    title: "Canale",
    field: "canale_ingresso",
    lookup: canaleOptions.reduce((obj, i) => _.set(obj, [i.value], i.name), {}),
    cellStyle: {whiteSpace: "nowrap"}
  },
]

export const OPTIONS = {
  filtering: true,
  showTitle: true,
  toolbar: false,
  pageSize: 10,
  sorting: true,
  headerStyle: {fontWeight: "bold", fontSize: 14, padding: 12},
  filterCellStyle: {padding: 12, maxWidth: 200},
  cellStyle: {padding: 12}
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  body: {
    editRow: {
      deleteText: "Eliminare il preventivo?",
    },
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} righe",
    labelRowsSelect: "Righe",
  }
};
