import React, {useContext, useState} from "react";

import {NotificationsContext} from "../../ui-components/Notifications";
import apiRequest from "../../services/apiRequest";

// UI
import AddProdDialog from "../Dialogs/AddProdDialog";
import MaterialTable from "material-table";
import _ from "lodash";

// Table Params
import { COLUMNS, OPTIONS, LOCALIZATION } from "./Params/Prodotti";
import { tableIcons } from "../../ui-components/TableIcons";


function ProdTable({ prod, setProd }) {
  const { push } = useContext(NotificationsContext);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const toggleDialog = () => setShowAddDialog(!showAddDialog);

  const _onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      let isEqual = true;
      Object.entries(newData).forEach(([key, value]) => {
        if (oldData[key] !== value) {
          isEqual = false;
        }
      });
      setImmediate(() => {
        if (isEqual) {
          resolve();
        } else {
          apiRequest.post(`conf/prod/${oldData.id}`, newData)
            .then((res) => {
              setProd({...prod, [res['id']]: res})
              push({title: "Prodotto aggiornato con successo", type: "success"});
              resolve();
            })
            .catch(() => {
              push({title: "Errore imprevisto", type: "error"});
              reject();
            });
        }
      });
    });

  const _onRowDelete = (newData) =>
    new Promise((resolve, reject) => {
      setImmediate(() => {
        apiRequest.delete(`conf/prod/${encodeURIComponent(newData.id)}`, newData)
          .then(() => {
            const { [newData.id]: tmp, ...rest } = prod;
            setProd({ ...rest });
            push({title: "Prodotto eliminato", type: "success"});
            resolve();
          })
          .catch(() => {
            push({title: "Impossibile eliminare il prodotto", type: "error"});
            reject();
          });
      });
    });

  return (
    <>
      <MaterialTable
        columns={COLUMNS}
        data={prod && Object.values(prod)}
        icons={tableIcons}
        isLoading={prod === undefined}
        options={OPTIONS}
        title={"Prodotti"}
        localization={LOCALIZATION}
        actions={[{
          icon: tableIcons.Add,
          tooltip: "Aggiungi prodotto",
          onClick: () => {toggleDialog()},
          isFreeAction: true,
        }]}
        editable={{
          onRowUpdate: _onRowUpdate,
          onRowDelete: _onRowDelete
      }}
      />
      {showAddDialog &&
      <AddProdDialog
        open={showAddDialog}
        toggleOpen={toggleDialog}
        prod={prod}
        setProd={setProd}
      />
      }
    </>
  );
}

export default React.memo(ProdTable, (props, nextProps) => {
  return _.isEqual(props.prod, nextProps.prod);
});
