import React from "react";
import Spinner from "./Spinner";

const buttonStyles = {
  default: "text-white bg-am-600 hover:bg-am-700",
  white: "text-gray-700 bg-white hover:bg-gray-50",
  transparent: "text-gray-700 bg-white",
  secondary: "text-am-700 bg-gray-100 hover:bg-gray-200",
  danger: "text-white bg-red-500 hover:bg-red-600",
};

const Button = ({
  full,
  className = "",
  children,
  styleType = "default",
  submitting = false,
  disabled = false,
  ...rest
}) => {
  let defaultClasses =
    "relative justify-center inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md focus:outline-none active:opacity-70 ";

  if (buttonStyles[styleType]) {
    defaultClasses += buttonStyles[styleType];
  } else {
    defaultClasses += buttonStyles.default;
  }

  return (
    <button
      disabled={submitting || disabled}
      className={`${defaultClasses} ${className} ${full ? "w-full" : ""} ${disabled ? "pointer-events-none" : ""}`}
      {...rest}
    >
      {children}
      {submitting ? (
        <Spinner classes={buttonStyles[styleType]} styleType={styleType} />
      ) : null}
    </button>
  );
};

export default Button;
