import "./App.css";
import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import PrivateRoute from "./ui-components/PrivateRoute";
import Dashboard from "./ui-components/Dashboard";
import Login from "./ui-components/pages/Login";
import Registration from "./ui-components/pages/Registration";
import Notifications from "./ui-components/Notifications";
import menu from "./ui-components/AppNavigation/menu";
import Logout from "./ui-components/pages/Logout";
import NotFound from "./ui-components/pages/NotFound";
import ErrorBoundary from "./ui-components/ErrorBoundary";
import filterRoutesByScopes from "./utils/filterRoutesByScopes";
import {DataProvider} from "./services/DataContext";

function App() {
  return (
    <ErrorBoundary>
      <Notifications>
        <DataProvider>
          <Switch>
            <PrivateRoute
              exact
              path="/"
              component={() => <Redirect to={{ pathname: "/preventivi" }} />}
            />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route exact path="/registration/:token" component={Registration} />

            <PrivateRoute
              path={[
                ...filterRoutesByScopes(menu)
                  .reduce(
                    (acc, route) => [
                      ...acc,
                      ...(route.dropdown ? route.dropdown : [route]),
                    ],
                    []
                  )
                  .map((route) => route.href),
                "/profile",
              ]}
              component={Dashboard}
            />
            <Route path="*" component={NotFound} />
          </Switch>
        </DataProvider>
      </Notifications>
    </ErrorBoundary>
  );
}

export default App;
