import moment from "moment";

export const initForm = {
  status: "stand by",
  dat_emissione: moment(),
  dat_chiusura: null,
  dat_consegna: null,
  flg_virtuo: false,
  provenienza: undefined,
  // cod_venditore: undefined,
  cliente: undefined,
  note: undefined,
  cod_prodotto: 0,
  cod_linea_prodotto: undefined,
  cod_modello: undefined,
  canale_ingresso: "Comunicazione",
  amt_listino_iva: undefined,
  amt_vendita_iva: undefined,
  val_sconto: 0,
  amt_oneri_consegna: undefined,
  amt_acquisto: 0,
  amt_margine: 0,
  val_margine: 0,
  val_ricarico: 0
}

export const statusOptions = [
  { value: "stand by", name: "Stand By" },
  { value: "chiuso", name: "Chiuso" },
  { value: "perso", name: "Perso" },
];

export const canaleOptions = [
  { value: "Comunicazione", name: "Comunicazione" },
  { value: "Web/Social", name: "Web/Social" },
  { value: "Passaparola", name: "Passaparola" },
  { value: "Di passaggio", name: "Di passaggio" },
  { value: "Già cliente", name: "Già cliente" },
];