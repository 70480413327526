import React, {useCallback, useContext, useEffect, useState} from "react";

// Services
import apiRequest from "../../services/apiRequest";

import Input from "../../ui-components/Input";
import Label from "../../ui-components/Label";
import Select from "../../ui-components/Select";
import Toggle from "../../ui-components/Toggle";
import Button from "../../ui-components/Button";
import Modal from "../../ui-components/Modal";
import { NotificationsContext} from "../../ui-components/Notifications";
import {canaleOptions, initForm, statusOptions} from "./constants";


import {TextField} from "@material-ui/core";
import DatePicker from "../../ui-components/DatePicker";

import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TrashIcon} from "@heroicons/react/solid";


function AddPrev({ data, setData, setView, toUpdate, conf, canSeeCosts }) {
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [form, setForm] = useState(toUpdate || initForm);
  const { push } = useContext(NotificationsContext);

  useEffect(() => {
    let sconto = 0
    let acquisto = 0
    let margine = 0
    let margine_perc = 0
    let ricarico = 0

    if (form.amt_listino_iva) {
      acquisto = Number(form.amt_listino_iva) / 1.22 * 0.58 * 0.9
      if (form.amt_vendita_iva) {
        const a = Number(form.amt_listino_iva)
        const b = Number(form.amt_vendita_iva)
        sconto = ((a-b)/a*100)
        margine = ((b/1.22)-acquisto - (form.amt_oneri_consegna || 0))
        margine_perc = margine/(b/1.22) * 100
        ricarico = margine / acquisto * 100
      }
    }

    setForm({...form,
      val_sconto: sconto ? sconto.toFixed(1) : 0,
      amt_acquisto: acquisto ? acquisto.toFixed(0) : 0,
      amt_margine: margine ? margine.toFixed(0) : 0,
      val_margine: margine_perc ? margine_perc.toFixed(1) : 0,
      val_ricarico: ricarico ? ricarico.toFixed(1) : 0
    })

  }, [form.amt_vendita_iva, form.amt_listino_iva]) // eslint-disable-line react-hooks/exhaustive-deps

  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        if (!form.cliente) {
          push({title: "Inserire il nome del cliente", type: "error"});
          return;
        }
        if (!form.dat_consegna) {
          push({title: "Inserire la data di consegna prevista", type: "error"});
          return;
        }
        if (!form.dat_chiusura && form.status === 'chiuso') {
          push({title: "Inserire la data di chiusura", type: "error"});
          return;
        }
        if (!form.provenienza) {
          push({title: "Specificare un punto di vendita", type: "error"});
          return;
        }
        if (!form.cod_prodotto || Number(form.cod_prodotto) === 0) {
          push({title: "Specificare un prodotto", type: "error"});
          return;
        }
        if (Number(form.cod_prodotto) !== 0 && Number(form.cod_prodotto) !== 4 && (!form.cod_modello || Number(form.cod_modello) === 0)) {
          push({title: "Specificare un modello", type: "error"});
          return;
        }
        if (!form.amt_listino_iva || ! form.amt_vendita_iva) {
          push({title: "Inserire gli importi", type: "error"});
          return;
        }
        setLoading(true);
        const payload = {
          ...form,
          dat_emissione: moment(form.dat_emissione).format('YYYY-MM-DD'),
          dat_consegna: moment(form.dat_consegna).format('YYYY-MM-DD'),
          dat_chiusura: form.dat_chiusura ? moment(form.dat_chiusura).format('YYYY-MM-DD') : undefined,
          cod_prodotto: Number(form.cod_prodotto),
          cod_modello: form.cod_modello ? Number(form.cod_modello) : undefined,
          cod_linea_prodotto: form.cod_linea_prodotto ? Number(form.cod_linea_prodotto) : undefined,
        }
        console.log(payload)
        const route = toUpdate ? `preventivi/${form['id']}` : 'preventivi'
        const res = await apiRequest.post(route, payload);
        setData({ ...data, [res['id']]: res });
        push({
          title: `Preventivo ${toUpdate ? 'aggiornato' : 'creato'} con successo`,
          type: "success",
        });
        setLoading(false);
        setView('list');
      } catch (error) {
        push({
          title: "Errore imprevisto",
          type: "error",
        });
      }
    },
    [form, setData, data, push] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const _onRowDelete = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        setDeleting(true);
        await apiRequest.delete(`preventivi/${encodeURIComponent(form['id'])}`);
        const { [form['id']]: tmp, ...rest } = data;
        setData({ ...rest });
        push({title: "Preventivo eliminato", type: "success"});
        setDeleting(false);
        setView('list');
      } catch (error) {
        push({
          title: "Errore imprevisto",
          type: "error",
        });
      }
    },
    [form, setData, data, push] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const fillFormValue = (col, val) => {
    setForm({...form, [col]: val})
  }

  return (
    <div>
      <form id="add-prev-form" onSubmit={_onSubmit}>
        <div className="grid gap-4 grid-cols-1 md:grid-cols-5 md:grid-flow-col md:gap-20">
          <div className="md:col-span-1">
            <DatePicker
              value={form.dat_emissione}
              label="Data emissione"
              onChange={(d) => fillFormValue('dat_emissione', d)}
            />
          </div>
          <div className="md:col-span-1">
            <Select
              options={statusOptions}
              value={form.status}
              onChange={(e) => fillFormValue('status', e.target.value)}
              label="Stato preventivo"
            />
          </div>
          <div className="md:col-span-2">
            <Input
              value={form.cliente}
              onChange={(e) => fillFormValue('cliente', e.target.value)}
              label="Riferimento"
              errorMessage="Inserisci il nome del riferimento"
              required
            />
          </div>
          <div className="md:col-span-1">
            <DatePicker
              value={form.dat_consegna}
              label="Data consegna"
              onChange={(d) => fillFormValue('dat_consegna', d)}
            />
          </div>
        </div>
        <div className="w-full">
          <Input
            value={form.note}
            onChange={(e) => fillFormValue('note', e.target.value)}
            label="Note"
            type="text"
          />
        </div>
        <div className="grid gap-4 grid-cols-1 md:grid-cols-6 md:grid-flow-col md:gap-10">
          <div className="md:col-span-2">
            <div className="flex flex-col mb-1">
              <Label>Provenienza</Label>
              <Autocomplete
                fullWidth
                value={form.provenienza}
                className="mb-4"
                options={conf['comuni'].map(i => `${i['comune']} (${i['cod_provincia']})`)}
                onChange={(e, value) => fillFormValue('provenienza', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label=""
                    inputProps={{...params.inputProps, style: {height: 10, padding: 4, fontSize: 12 }}}
                    style={{marginTop: 4}}
                  />
                )}
              />
            </div>
          </div>
          <div className="md:col-span-2">
            <Select
              options={canaleOptions}
              value={form.canale_ingresso}
              onChange={(e) => fillFormValue('canale_ingresso', e.target.value)}
              label="Canale ingresso"
            />
          </div>
          <div className="md:col-span-1">
            <div className="ml-6">
              <Label>Virtuo</Label>
              <Toggle
                checked={form.flg_virtuo}
                onChange={() => setForm({...form, flg_virtuo: !form.flg_virtuo})}
              />
            </div>
          </div>
          <div className="md:col-span-1">
            <DatePicker
              value={form.dat_chiusura}
              label="Chiusura o ricontatto"
              onChange={(d) => fillFormValue('dat_chiusura', d)}
            />
          </div>
        </div>
        <div className="grid gap-4 grid-cols-1 md:grid-cols-3 md:gap-10">
          <div>
            <Select
              options={[{value: 0, name: ""}].concat(conf['prodotti'].map(i => ({value: i['id'], name: i['des_prodotto']})))}
              value={form.cod_prodotto}
              onChange={(e) => {
                const val = e.target.value
                if (val && Number(val) !== 0) {
                  fillFormValue('cod_prodotto', e.target.value)
                } else {
                  setForm({...form, cod_prodotto: val, cod_modello: undefined, cod_linea_prodotto: undefined})
                }
              }}
              label="Prodotto preventivato"
            />
          </div>
          <div>
            <Select
              options={[{value: 0, name: ""}].concat(conf['modelli'].filter(i => i.prod_id === Number(form.cod_prodotto)).map(i => ({value: i['id'], name: i['des_modello']})))}
              value={form.cod_modello}
              onChange={(e) => {
                const val = e.target.value
                if (val && Number(val) !== 0) {
                  setForm({...form, cod_modello: val, cod_linea_prodotto: conf['modelli'].filter(i => i.id === Number(val))[0].line_id})
                } else {
                  setForm({...form, cod_modello: val, cod_linea_prodotto: undefined})
                }
              }}
              label="Modello"
              disabled={conf['modelli'].filter(i => i.prod_id === Number(form.cod_prodotto)).length === 0}
            />
          </div>
          <div>
            <Input
              value={form.cod_linea_prodotto ? conf['linee'].filter(i => i.id === Number(form.cod_linea_prodotto))[0]['des_linea'] : ''}
              label="Linea prodotto"
              disabled={true}
            />
          </div>
        </div>
        <div className="grid gap-4 grid-cols-1 md:grid-cols-4 md:gap-10">
          <div>
            <Input
              value={form.amt_listino_iva}
              label="Listino ivato (€)"
              onChange={(e) => fillFormValue('amt_listino_iva', e.target.value.replace(/\D/,''))}
              errorMessage="Inserisci l'importo"
              required
            />
          </div>
          <div>
            <Input
              value={form.amt_vendita_iva}
              label="Vendita Iva inc. (€)"
              onChange={(e) => fillFormValue('amt_vendita_iva', e.target.value.replace(/\D/,''))}
              errorMessage="Inserisci l'importo"
              required
            />
          </div>
          {canSeeCosts &&
            <div>
              <Input
                value={form.val_sconto ? `${form.val_sconto}%` : ''}
                label="Sconto"
                disabled={true}
              />
            </div>
          }
          <div>
            <Input
              value={form.amt_oneri_consegna}
              label="Oneri consegna (€)"
              onChange={(e) => fillFormValue('amt_oneri_consegna', e.target.value.replace(/\D/,''))}
              errorMessage="Inserisci l'importo"
              required
            />
          </div>
        </div>
        {canSeeCosts &&
          <div className="grid gap-4 grid-cols-1 md:grid-cols-4 md:gap-10">
            <div>
              <Input
                value={form.amt_acquisto || ''}
                label="Acquisto iva esc. (€)"
                disabled={true}
              />
            </div>
            <div>
              <Input
                value={form.amt_margine || ''}
                label="Margine iva esc. (€)"
                disabled={true}
              />
            </div>
            <div>
              <Input
                value={form.val_margine ? `${form.val_margine}%` : ''}
                label="Margine"
                disabled={true}
              />
            </div>
            <div>
              <Input
                value={form.val_ricarico ? `${form.val_ricarico}%` : ''}
                label="Ricarico"
                disabled={true}
              />
            </div>
          </div>
        }
        <div className="mt-4 mb-10 grid gap-4 grid-cols-2">
          <div>
            {toUpdate &&
              <Button styleType="white" submitting={deleting} type="button" onClick={() => setConfirm(true)}>
                <TrashIcon className="h-4 w-4 text-red-400 mr-2"/> <span className="text-red-400">ELIMINA</span>
              </Button>
            }
          </div>
          <div className="flex items-end justify-end">
            <Button submitting={loading} type="submit">
              {toUpdate ? 'AGGIORNA' : 'AGGIUNGI'}
            </Button>
          </div>
        </div>
      </form>
      {confirm &&
        <Modal onExit={() => setConfirm(false)}>
          <div className="m-2">
            <p className="font-bold">Sei sicuro di voler eliminare il preventivo?</p>
            <p>L'operazione è irreversibile</p>
            <div className="flex items-end justify-end mt-2">
              <Button styleType="danger" submitting={deleting} type="button" onClick={_onRowDelete}>
                CONFERMO
              </Button>
            </div>
          </div>
        </Modal>
      }

    </div>
  );
}

export default AddPrev;
