export const COLUMNS = [
  {
    title: "Prodotto",
    field: "des_prodotto"
  }
]

export const OPTIONS = {
  filtering: false,
  search: false,
  showTitle: true,
  toolbar: true,
  paging: false,
  sorting: true,
  headerStyle: {fontWeight: "bold", fontSize: 14, padding: 12},
  cellStyle: {padding: 12, minWidth: 200}
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  body: {
    editRow: {
      deleteText: "Eliminare il prodotto?",
    },
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} righe",
    labelRowsSelect: "Righe",
  }
};
